// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  min-width: 500px;
  max-width: 600px;
}
.modal-container .input-container {
  margin-top: 15px;
}

.modal-header {
  height: 47px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F5F6F8;
}
.modal-header h3 {
  color: #2c2c2c;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  padding: 0 20px;
}

.modal-body {
  padding: 12px 20px 20px;
}
.modal-body p {
  color: #2c2c2c;
  font-size: 14px;
  margin: 0;
  letter-spacing: 0.08px;
}

.modal-footer .button-container {
  display: flex;
  justify-content: flex-end;
  padding: 0 15px 22px;
}

.list-matters {
  background-color: #f6f9fa;
  padding: 10px;
}
.list-matters p {
  color: #1252af;
  font-size: 14px;
  font-weight: 900;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/confirm-modal/confirm-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;AACF;AAAE;EACE,gBAAA;AAEJ;;AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,gCAAA;AACF;AACE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,SAAA;EACA,eAAA;AACJ;;AAGA;EACE,uBAAA;AAAF;AAEE;EACE,cAAA;EACA,eAAA;EACA,SAAA;EACA,sBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,yBAAA;EACA,oBAAA;AADF;;AAIA;EACE,yBAAA;EACA,aAAA;AADF;AAEE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".modal-container {\n  min-width: 500px;\n  max-width: 600px;\n  .input-container {\n    margin-top: 15px;\n  }\n}\n\n.modal-header {\n  height: 47px;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #F5F6F8;\n\n  h3 {\n    color: #2c2c2c;\n    font-size: 15px;\n    font-weight: 500;\n    margin: 0;\n    padding: 0 20px;\n  }\n}\n\n.modal-body {\n  padding: 12px 20px 20px;\n\n  p {\n    color: #2c2c2c;\n    font-size: 14px;\n    margin: 0;\n    letter-spacing: 0.08px;\n  }\n}\n\n.modal-footer .button-container {\n  display: flex;\n  justify-content: flex-end;\n  padding: 0 15px 22px;\n}\n\n.list-matters {\n  background-color: #f6f9fa;\n  padding: 10px;\n  p {\n    color: #1252af;\n    font-size: 14px;\n    font-weight: 900;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
