import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DocumentExtended, IUser, TaskExtended, TaskSave } from '@app/models';
import { MatterExtended } from '@app/models/matter-extended';
import { TimeFormatter } from '@app/helpers';
import { disappearanceAnimation, fadeTopAnimation } from '@app/helpers/fadeAnimation';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { DocumentSave } from '@app/models/document-save';
import {
  DocumentUploadModalComponent,
} from '@app/commonComponents/modals/default-modals/document-upload-modal/document-upload-modal.component';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';
import { selectMatterListForSelect } from '@app/store/selectors/matter.selector';
import { AddTask, AddTaskWithDocuments, UpdateTask } from '@app/store/actions/task.actions';
import { AddDocuments, DeleteDocuments } from '@app/store/actions/document.actions';
import { LoadMattersForList } from '@app/store/actions/matter.actions';
import { AuthService } from '@app/auth/_services/auth.service';
import { MatterFilters } from '@app/models/matter-filters';
import { TasksService } from '@app/modules/main-tasks/services/tasks.service';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from '@app/modules/reusable/documents/services/document.service';
import { environment } from '@environments/environment';
import { selectListUsersForSelect } from '@app/modules/settings/store/selectors/team-member.selector';
import { LoadUsersForList } from '@app/modules/settings/store/actions/team-member.actions';
import { EmitType } from '@syncfusion/ej2-base';
import { MatterService } from '@app/modules/main-matters/services/matter.service';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { ComboBoxComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { selectCurrentUser } from '@app/auth/_store/selectors/auth.selector';
import { DocRelation } from '@app/models/doc-relation';
import { ToastrService } from 'ngx-toastr';
import { Helpers } from '@app/helpers/helpers';
import { TeamMemberFilter } from '@app/models/interfaces/team-member-filter';
import { ReminderExtended, TaskReminder } from '@app/models/reminder-extended';
import { DebounceService } from '@app/helpers/debounce.service';
import { TasksModalDebounceEnum } from '@app/enums/debounce-keys.enum';
import { CourtSession } from '@app/models/court-session';
import { IInfoEntity } from '@app/models/comment';

@Component({
  templateUrl: './t-e-modal.component.html',
  styleUrls: ['./t-e-modal.component.scss'],
  animations: [disappearanceAnimation, fadeTopAnimation],
})
export class TasksEventsModalComponent implements OnInit, OnDestroy {

  @ViewChild('file', { static: false }) inputFile: ElementRef;
  @ViewChild('mattersComboBox', { static: false }) public comboBoxMatter: ComboBoxComponent;
  @ViewChild('usersComboBox', { static: false }) public comboBoxUser: ComboBoxComponent;

  @ViewChild('dateReminders', { static: false })
  public dateReminders;
  public sorting = 'Ascending';
  public locale$ = this.appSettingsService.getLang;
  private subscription = new Subscription();
  private mattersList: MatterExtended[];

  priorityToggleButton = false;
  remindToggleButton = false;
  // pairs of documents and media to save them when expense is saved
  documentsToAdd: { document: DocumentSave; mediaFile: File; reminders: ReminderExtended; relations: DocRelation[] }[] = [];
  // documents ids to delete when expense is saved
  documentsToDelete: number[] = [];
  // documents to show in list
  documentsToShow: { name: string; id?: number; media: { type: 'document' } }[] = [];
  fileData: File = null;

  matters: MatterExtended[];
  users: IUser[];

  submittedForm = false;
  remindFields = false;

  dateFormat = CustomDateAdapter.dateFormat;
  dateTimeFormat = CustomDateAdapter.currentDateTimeFormat;
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;

  taskForm: UntypedFormGroup;
  descIn = 'unfocus';

  newDate = new Date();
  todayDate = new Date();
  dateValid = false;
  reminderId = null;
  reminder: TaskReminder;
  filterMatters: MatterFilters = {
    name: '',
    status: 'open',
  };

  filtersUser: TeamMemberFilter = {
    name: '',
    status: 'active',
    'companyAccesses.enable': true,
  };


  entityInfo: IInfoEntity;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      startDate?: Date;
      endDate?: Date;
      contact?: { id: number };
      responsible?: { id: number };
      matter?: { id: number };
      correspondence?: { id: number, matter?: MatterExtended };
      task?: TaskExtended;
      duplicate?: boolean;
      matterPlanData?: any;
      document?: DocumentExtended;
      courtSession?: CourtSession;
      type?: string;
    },
    public dialogRef: MatDialogRef<TasksEventsModalComponent>,
    private dialog: MatDialog,
    private matterService: MatterService,
    private teamMemberService: TeamMemberService,
    private timeFormatter: TimeFormatter,
    private store: Store<IAppState>,
    private documentService: DocumentService,
    private reminderService: TasksService,
    private authService: AuthService,
    private appSettingsService: AppSettingsService,
    private toastrService: ToastrService,
    private debounceService: DebounceService,
  ) {
  }

  get f() {
    return this.taskForm;
  }

  get minEndDate(): Date {
    return this.taskForm.get('startDate').value || CustomDateAdapter.startDate;
  }

  ngOnInit() {
    this.createForm();
    if (this.data && this.data.matter) {
      this.taskForm.get('matter').disable();
    }
    if (this.data && this.data.responsible) {
      this.taskForm.get('taskUsers').disable();
    }
    if (this.data && this.data.task) {
      this.initFormValues(this.data.task);
      if (!this.data.duplicate) {
        this.subscription.add(
          this.documentService.getDocumentsByTask(this.data.task.id).subscribe(x => {
            this.documentsToShow = x.map(doc => {
              return { name: doc.name, id: doc.id, media: { type: 'document' } };
            });
          }),
        );
      }
    }

    // for event
    if (this.data && this.data.courtSession) {
      this.initFormValuesCourtSession(this.data.courtSession);
    }
    if (this.data && this.data.contact && this.mattersList) {
      this.filterMatters['contact.id'] = this.data.contact.id;
      this.matters = this.mattersList.filter(matter => matter.contact.id === this.data.contact.id);
    }

    if (this.data && this.data.task && !this.data.duplicate) {
      this.reminderService.getTaskReminder(this.data.task.id).subscribe(info => {
        if (info && info.length !== 0) {
          this.reminder = info[0];
          this.remindFields = true;
          this.reminderId = info[0].id;
          this.newDate = info[0].reminderDate as Date;
        }
      });
    }

    if (this.data && this.data.document && this.data.document.id) {
      this.documentsToShow = [this.data.document as any];
    }
    this.setSelectModels();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.debounceService.removeDebounceData();
  }

  checkSubmit() {
    this.submittedForm = true;
  }

  remindValue(event): void {
    this.remindFields = event.target.checked;
    if (!event.target.checked) {
      // this.newDate = null;
    } else {
      const minutes = Math.ceil((new Date().getMinutes() + 30) / 10) * 10;
      this.todayDate.setMinutes(minutes);
      this.newDate = this.todayDate;
    }
  }

  // функції для витягу дати нагадування з html
  changeDate(): void {
    this.newDate = this.dateReminders.value;
    const date5 = new Date();
    date5.setMinutes(date5.getMinutes() + 5);
    if (!(date5 > this.newDate || (this.newDate == null && this.remindFields))) {
      this.dateValid = false;
    }
  }

  startDatePending() {
    return this.todayDate;
  }

  onFileChanged(fileInput) {
    this.fileData = fileInput.target.files[0] as File;
    if (!Helpers.checkSizeFile(this.fileData, 20)) {
      this.fileData = null;
      this.toastrService.error(this.translate.instant('documents.fileTooBig20'));
      return;
    }
    if (this.fileData) {
      this.documentUploadModal(this.fileData);
    }
    this.inputFile.nativeElement.value = null;
  }

  deleteDocument(document: { name: string; id?: number; media: { type: 'document' } }) {
    if (document.id) {
      this.documentsToDelete.push(document.id);
    } else {
      const position = this.documentsToAdd.indexOf(this.documentsToAdd.filter(x => x.document.name === document.name)[0]);
      if (position !== -1) {
        this.documentsToAdd.splice(position, 1);
      }
    }
    this.documentsToShow.splice(this.documentsToShow.indexOf(document), 1);
  }

  saveAndClose() {
    this.checkSubmit();
    this.taskForm.updateValueAndValidity();
    if (!this.taskForm.valid) {
      return;
    }
    const date5 = new Date();
    date5.setMinutes(date5.getMinutes() + 5);
    if ((date5 > this.newDate || this.newDate == null) && this.remindFields) {
      this.dateValid = true;
      return;
    }
    const payload = {
      close: true,
      reminders: {
        reminderDate: this.newDate,
        id: this.reminderId,
      },
      check: this.remindFields,
      task: this.data && this.data?.task ? this.formDataToTaskSave(true) : null,
    };
    if (this.reminder) {
      if (this.reminder.reminderDate === this.newDate) {
        payload.reminders = null;
      }
    }

    if (this.data && this.data.task && !this.data.duplicate) {
      this.updateTask();
    } else {
      this.addTask(true);
    }

    this.dialogRef.close(payload);
  }

  saveAndClear(): void {
    this.checkSubmit();

    this.taskForm.updateValueAndValidity();
    if (!this.taskForm.valid) {
      return;
    }

    const date5 = new Date();
    date5.setMinutes(date5.getMinutes() + 5);
    if ((date5 > this.newDate || this.newDate == null) && this.remindFields) {
      this.dateValid = true;
      return;
    }
    const payload = {
      close: true,
      reminders: {
        reminderDate: this.newDate,
        id: this.reminderId,
      },
      check: this.remindFields,
    };
    if (this.reminder) {
      if (this.reminder.reminderDate === this.newDate) {
        payload.reminders = null;
      }
    }

    // this.loader.open();
    if (this.data && this.data.task && !this.data.duplicate) {
      this.updateTask();
    } else {
      this.addTask(false);
    }
    this.documentsToAdd = [];
    this.documentsToDelete = [];
    this.documentsToShow = [];
    if (this.data && this.data.task) {
      delete this.data.task;
    }
    this.resetFormValues();
  }

  saveAndContinue(): void {
    this.checkSubmit();

    this.taskForm.updateValueAndValidity();
    if (!this.taskForm.valid) {
      return;
    }
    const date5 = new Date();
    date5.setMinutes(date5.getMinutes() + 5);
    if ((date5 > this.newDate || this.newDate == null) && this.remindFields) {
      this.dateValid = true;
      return;
    }
    const payload = {
      close: true,
      reminders: {
        reminderDate: this.newDate,
        id: this.reminderId,
      },
      check: this.remindFields,
    };
    if (this.reminder) {
      if (this.reminder.reminderDate === this.newDate) {
        payload.reminders = null;
      }
    }
    // this.loader.open();
    if (this.data && this.data.task && !this.data.duplicate) {
      this.updateTask();
    } else {
      this.addTask(false);
    }
    this.documentsToAdd = [];
    this.documentsToDelete = [];
    this.documentsToShow = [];
    if (this.data && this.data.task) {
      delete this.data.task;
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  updateDateEnd(dateStart) {
    const endDate = new Date(this.taskForm.get('endDate').value);
    if (dateStart.value > endDate) {
      if (this.data.type === 'task') {
        this.taskForm.get('endDate').setValue(dateStart.value);
      } else {
        const start = new Date(dateStart.value);
        this.taskForm.get('endDate').setValue(new Date(start.setMinutes(start.getMinutes() + 30)));
      }
    }
  }

  private createForm() {
    let startDateControl = this.formBuilder.control(this.data && this.data.startDate ? new Date(this.data.startDate) : new Date(), [
      Validators.required,
    ]);
    let endDateControl = this.formBuilder
      .control(this.data && this.data.endDate ? new Date(this.data.endDate) : new Date(), [Validators.required]);


    if (this.data.type === 'event') {
      startDateControl = this.formBuilder.control(
        this.data && this.data.startDate ? new Date(this.data.startDate) : this.timeFormatter.getNearestTime(new Date()),
        [Validators.required],
      );
      endDateControl = this.formBuilder.control(
        this.data && this.data.endDate
          ? new Date(this.data.endDate)
          : this.timeFormatter.getNearestTime(new Date(new Date().setMinutes(new Date().getMinutes() + 30))),
        [Validators.required],
      );
    }

    this.taskForm = this.formBuilder.group({
      name: this.formBuilder.control('', [Validators.required, Validators.maxLength(255)]),
      startDate: startDateControl,
      endDate: endDateControl,
      matter: this.formBuilder.control(this.data && this.data.matter ? this.data.matter.id : null, Validators.required),
      location: this.formBuilder.control('', [Validators.maxLength(255)]),
      estimate: this.formBuilder.control('', [this.timeFormatter.correctTimeFormatValidator()]),
      description: this.formBuilder.control(''),
      taskUsers: this.formBuilder.control([this.authService.currentUserValue.id], [Validators.required]),
      status: this.formBuilder.control('new'),
      priority: this.formBuilder.control('medium'),
      court: this.formBuilder.control(false),
      courtSession: this.formBuilder.control(null),
    });
    if (this.data.type === 'task') {
      this.taskForm.get('estimate').addValidators(Validators.required);
    }

    if (this.data && this.data.matterPlanData) {
      this.taskForm.patchValue({
        name: this.data.matterPlanData.description,
        estimate: this.data.matterPlanData.duration,
      });
    }
  }

  private initFormValues(task: TaskExtended): void {
    this.taskForm.get('name').setValue(task.name);
    this.taskForm.get('startDate').setValue(new Date(task.startDate));
    this.taskForm.get('endDate').setValue(new Date(task.endDate));
    this.taskForm.get('matter').setValue(task.matter ? task.matter.id : null);
    this.taskForm.get('location').setValue(task.location);
    this.taskForm.get('priority').setValue(task.priority);
    this.taskForm.get('court').setValue(task.court);
    this.taskForm.get('estimate').setValue(task.estimate !== null ? this.timeFormatter.convertSecondsToTableString(task.estimate) : 0);
    this.taskForm.get('description').setValue(task.description);
    this.taskForm
      .get('taskUsers')
      .setValue(task?.taskUsers && task.taskUsers.length > 0 ? task.taskUsers.map(taskUser => taskUser.user.id) : null);
    this.taskForm.get('status').setValue(!this.data.duplicate && task.status);
  }

  private resetFormValues(): void {
    this.taskForm.get('name').reset();
    this.taskForm.get('startDate').setValue(new Date());
    this.taskForm.get('endDate').setValue(new Date());
    this.taskForm.get('matter').reset();
    this.taskForm.get('location').reset();
    this.taskForm.get('estimate').reset();
    this.taskForm.get('description').setValue('');
    this.taskForm.get('taskUsers').setValue([this.authService.currentUserValue.id]);
    this.taskForm.get('status').reset();
    this.remindFields = false;
    this.submittedForm = false;
  }

  private formDataToTaskSave(isUpdated: boolean): TaskSave {
    const task: TaskSave = {
      id: isUpdated ? this.data?.task?.id : null,
      name: this.taskForm.get('name').value,
      startDate: this.data.type === 'task' ?
        CustomDateAdapter.convertToUniversalDateString(this.taskForm.get('startDate').value) :
        this.taskForm.get('startDate').value,
      endDate: this.data.type === 'task' ?
        CustomDateAdapter.convertToUniversalDateString(this.taskForm.get('endDate').value) :
        this.taskForm.get('endDate').value,
      description: this.taskForm.get('description').value,
      location: this.taskForm.get('location').value,
      priority: this.taskForm.get('priority').value,
      court: this.taskForm.get('court').value,
      courtSession: this.taskForm.get('courtSession').value,
      type: this.data.type.toLowerCase(),
      status: this.taskForm.get('status').value,
      estimate:
        this.taskForm.get('estimate').value !== '' ? this.timeFormatter.convertStringToSeconds(this.taskForm.get('estimate').value) : 0,
      matter: this.taskForm.get('matter').value ? (this.taskForm.get('matter').value as number) : null,
      taskUsers: [],
    };
    if (this.data?.task) {
      for (const selectUserId of this.taskForm.get('taskUsers').value) {
        if (!task.taskUsers.find(sendTaskUser => sendTaskUser.user === selectUserId)) {
          task.taskUsers.push({
            id: this.data.task.taskUsers.find(initialTaskU => initialTaskU.user.id === selectUserId)?.id,
            user: selectUserId,
          });
        }
      }
    } else {
      for (const item of this.taskForm.get('taskUsers').value) {
        task.taskUsers.push({
          user: item,
        });
      }
    }
    if (this.data && this.data.correspondence && this.data.correspondence.id) {
      task.correspondence = this.data.correspondence.id;
    }

    return task;
  }

  private addTask(close?): void {
    const reminder = {
      reminders: {
        reminderDate: this.newDate,
        id: this.reminderId,
      },
      check: this.remindFields,
    };
    const task = this.formDataToTaskSave(false);
    if (!task.location) {
      task.location = '';
    }
    if (!task.court) {
      task.court = false;
    }
    delete task.status;
    this.checkDateEvent(task).subscribe(res => {
      if (res.member && res.member.length) {
        this.infoAfterCreateEventModal(res.member)
          .afterClosed()
          .subscribe(result => {
            if (result === true) {
              this.save(task, reminder, close);
            } else if (result === 'close') {
              this.dialogRef.close();
            }
          });
      } else {
        this.save(task, reminder, close);
      }
    });

  }

  save(event, reminder, close) {
    if (this.documentsToAdd.length > 0) {
      this.store.dispatch(new AddTaskWithDocuments({ documents: this.documentsToAdd, task: event, reminder }));
    } else {
      this.store.dispatch(new AddTask({ task: event, reminder }));
    }
    if (close) {
      this.dialogRef.close({
        close: true,
        reminders: {
          reminderDate: this.newDate,
          id: this.reminderId,
        },
        check: this.remindFields,
        task: this.data && this.data.task ? this.formDataToTaskSave(true) : null,
      });
    }
  }

  checkDateEvent(event: TaskSave) {
    return this.reminderService.getTasks(environment.defaultPage, environment.defaultItemsCount, {
      status: 'upcoming',
      type: 'event',
      'startDate[before]': CustomDateAdapter.getDateWithTimeUtcZ(event.startDate),
      'endDate[after]': CustomDateAdapter.getDateWithTimeUtcZ(event.endDate),
      'taskUsers.user.id[]': event.taskUsers.map(item => item.user) as [],
    });
  }

  infoAfterCreateEventModal(events): MatDialogRef<ConfirmModalComponent, any> {
    const dialogConfig = new MatDialogConfig();
    const title = 'conflictEventsTitle';
    let mainTxt = '';
    if (events.length === 1) {
      mainTxt =
        this.translate.instant('conflictEventsDescriptionEvent') +
        `<a href='/tasks/card/${events[0].id}'> ${events[0].name} </a>.` +
        '<br>' +
        this.translate.instant('conflictEventsDescriptionEnd');
    } else {
      mainTxt = this.translate.instant('conflictEventsDescriptionEvents');
      for (let i = 0; i < events.length; i++) {
        mainTxt += `<a href='/tasks/card/${events[i].id}'> ${events[i].name} </a>`;
        mainTxt += i < events.length - 1 ? ', ' : '';
      }
      mainTxt += '. <br>' + this.translate.instant('conflictEventsDescriptionEnd');
    }

    dialogConfig.panelClass = 'default-mat-dialog';

    dialogConfig.data = {
      modalTyle: 'blue',
      noButtonTxt: this.translate.instant('back'),
      yesButtonTxt: this.translate.instant('createAnyway'),
      title,
      mainTxt,
      eventModal: true,
    };
    return this.dialog.open(ConfirmModalComponent, dialogConfig);
  }

  private updateTask(): void {
    const reminder = {
      reminders: {
        reminderDate: this.newDate,
        id: this.reminderId,
      },
      check: this.remindFields,
    };
    const task = this.formDataToTaskSave(true);
    this.store.dispatch(new UpdateTask({ task, reminder }));
    if (this.documentsToAdd.length > 0) {
      this.store.dispatch(new AddDocuments(this.documentsToAdd));
    }
    if (this.documentsToDelete.length > 0) {
      this.store.dispatch(new DeleteDocuments(this.documentsToDelete));
    }
  }

  // handler for uploading of the documents
  private documentUploadModal(file: File) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = ['default-mat-dialog', 'document-upload-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { fileName: file.name, skipMatter: true };

    const dialogRef = this.dialog.open(DocumentUploadModalComponent, dialogConfig);
    const subscr = dialogRef.afterClosed().subscribe(x => {
      if (x) {
        x.document.task = this.data && this.data?.task ? this.data.task.id : null;

        this.documentsToAdd.push({ document: x.document, mediaFile: this.fileData, reminders: x.reminders, relations: x.relations });
        this.documentsToShow.push({ name: x.document.name, media: { type: 'document' } });
      }
    });
    this.subscription.add(subscr);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  private setSelectModels(): void {
    this.loadUsers();
    this.loadMatters();
  }

  loadMatters() {
    if (this.data?.contact) {
      this.filterMatters['contact.id'] = this.data.contact.id;
      this.subscription.add(
        this.matterService.get(environment.defaultPage, environment.countItemInSelect, this.filterMatters).subscribe(x => {
          this.matters = x.member;
          this.setMattersData();
        }),
      );
    } else {
      this.subscription.add(
        this.store.select(selectMatterListForSelect).subscribe(matters => {
          if (!matters) {
            this.store.dispatch(new LoadMattersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
            return;
          }
          this.matters = matters;
          this.setMattersData();
        }),
      );
    }
  }

  private setMattersData() {
    let currentMatter;
    if (this.data && this.data.correspondence?.matter) {
      this.taskForm.get('matter').setValue(this.data.correspondence.matter ? this.data.correspondence.matter.id : null);
      currentMatter = this.data.correspondence.matter;
    }

    if (this.data && this.data.task) {
      this.taskForm.get('matter').setValue(this.data.task.matter ? this.data.task.matter.id : null);
      currentMatter = this.data.task.matter;
    }

    if (this.data && this.data.matter) {
      this.taskForm.get('matter').setValue(this.data.matter ? this.data.matter.id : null);
      currentMatter = this.data.matter;
    }
    if (this.data && this.data.courtSession) {
      this.taskForm.get('matter').setValue(this.data.courtSession.matter.id);
      currentMatter = this.data.courtSession.matter;
      this.taskForm.get('matter').disable();
    }
    if (currentMatter && !this.matters.find(x => x.id === currentMatter.id)) {
      this.matters.push(currentMatter);
      this.taskForm.get('matter').setValue(currentMatter.id);
    }
    if (this.data && this.data.contact) {
      this.filterMatters['contact.id'] = this.data.contact.id;
      this.matters = this.matters.filter(matter => {
        if (matter.contact) {
          if (matter.contact.id === this.data.contact.id) {
            return matter;
          }
        }
      });
    }
  }


  loadUsers() {
    if (this.entityInfo?.entityId) {
      this.filtersUser['workGroups.id'] = this.entityInfo.workGroup;
      this.teamMemberService.get(environment.defaultPage, environment.countItemInSelect, this.filtersUser).subscribe(res => {
        this.users = res.member;
        if (this.users.length) {
          const ids: number[] = [];
          if (this.data?.task) {
            this.data.task.taskUsers.forEach(taskUser => {
              if (!this.users.some(u => u.id === taskUser.user.id)) {
                this.users.push((taskUser.user as unknown) as IUser);
              }
              ids.push(taskUser.user.id);
            });

            this.taskForm.get('taskUsers').setValue(ids);
          } else {
            this.taskForm.get('taskUsers').setValue([this.authService.currentUserValue.id]);
          }
        }
      });
    } else {
      this.subscription.add(
        this.store.select(selectListUsersForSelect).subscribe(resp => {
          if (!resp) {
            this.store.dispatch(new LoadUsersForList({ page: environment.defaultPage, itemsPerPage: environment.countItemInSelect }));
            return;
          }
          this.users = resp;
          this.setDataAfterLoadUser();
        }),
      );
    }
  }

  setDataAfterLoadUser() {
    if (this.data && this.data.task) {
      this.filtersUser['workGroups.id'] = this.entityInfo?.workGroup;
      this.teamMemberService.get(environment.defaultPage, environment.countItemInSelect, this.filtersUser).subscribe(res => {
        this.users = res.member;
        for (const tUser of this.data.task.taskUsers) {
          if (!this.users.find(usr => usr.id === tUser.user.id)) {
            this.users.push(tUser.user);
          }
        }
        if (this.users.length) {
          this.taskForm.get('taskUsers').setValue(this.data.task.taskUsers.map(taskUser => taskUser.user.id));
        }
      });
    } else if (!this.users.find(x => x.id === this.authService.currentUserValue.id)) {
      const selectSubcr = this.store.select(selectCurrentUser).subscribe(currentUser => {
        if (currentUser) {
          this.users.push(currentUser);
          this.taskForm.get('taskUsers').setValue([this.authService.currentUserValue.id]);
          selectSubcr.unsubscribe();
        }
      });
    } else {
      this.taskForm.get('taskUsers').setValue([this.authService.currentUserValue.id]);
    }
  }

  public onFilteringMatters: EmitType<any> = (e: FilteringEventArgs) => {
    this.filterMatters.name = e.text;
    this.comboBoxMatter?.showSpinner();
    this.debounceService.loadDataDebounce(
      TasksModalDebounceEnum.matters,
      this.matterService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filterMatters]);
    this.debounceService.getValue(TasksModalDebounceEnum.matters).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.matters = resp?.member as any;
      this.comboBoxMatter?.hideSpinner();
    });
  };

  public onFilteringUsers: EmitType<any> = (e: FilteringEventArgs) => {
    this.filtersUser.name = e.text;
    this.comboBoxUser?.showSpinner();
    this.debounceService.loadDataDebounce(
      TasksModalDebounceEnum.users,
      this.teamMemberService, 'get',
      [environment.defaultPage, environment.countItemInSelect, this.filtersUser]);
    this.debounceService.getValue(TasksModalDebounceEnum.users).subscribe(resp => {
      e.updateData(resp?.member as any);
      this.comboBoxUser?.hideSpinner();
    });
  };

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal'),
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  fixPopUp(event, selectName) {
    const e = event?.event || event;
    if (!(e['target'] && e['target']['className'] === 'e-dropdownbase')) {
      this[selectName].hidePopup();
    }
  }

  selectUser() {
    setTimeout(() => {
      const multiselectInput = document.querySelector(`.t-e-modal .e-dropdownbase`);
      multiselectInput.setAttribute('placeholder', this.translate.instant('common.typeHere'));
    }, 10);
  }

  fixHeightList(selectName) {
    setTimeout(() => {
      this[selectName].focusOut();
    }, 0);
  }

  private initFormValuesCourtSession(courtSession): void {
    console.log(courtSession);

    this.taskForm.get('name').setValue('Представництво у судовому засідання по справі № ' + courtSession.juscourtSession.caseNumb);
    this.taskForm.get('court').setValue(true);
    const location = courtSession.juscourtSession.courtRoom ?
      courtSession.juscourtSession.court?.name + ', кабінет № ' + courtSession.juscourtSession.courtRoom : courtSession.juscourtSession.court?.name;

    this.taskForm.get('location').setValue(location);
    const startHours = new Date(courtSession.juscourtSession.courtDate).getUTCHours();
    const startMinutes = new Date(courtSession.juscourtSession.courtDate).getUTCMinutes();
    const startLocalDate = new Date(courtSession.juscourtSession.courtDate);
    startLocalDate.setHours(startHours);
    startLocalDate.setMinutes(startMinutes);
    const courtDate = new Date(startLocalDate);
    let courtEndDate = new Date(startLocalDate);
    courtEndDate = this.timeFormatter.getNearestTime(new Date(courtEndDate.setMinutes(courtEndDate.getMinutes() + 30)));

    this.taskForm.get('startDate').setValue(courtDate);
    this.taskForm.get('endDate').setValue(courtEndDate);
    this.taskForm.get('courtSession').setValue(courtSession.id);
    let description = `${courtSession.juscourtSession.caseDescription}`;
    if (courtSession.juscourtSession.presidentJudge) {
      description += `\r\nГоловуючий - ${courtSession.juscourtSession.presidentJudge.name}`;
    }
    if (courtSession.juscourtSession.speakerJudge) {
      description += `\r\nДововідач - ${courtSession.juscourtSession.speakerJudge.name}\r\n`;
    }
    if (courtSession.juscourtSession.collegialJudges.length) {
      Array.from(courtSession.juscourtSession.collegialJudges).forEach((judge) => {
        description += `\r\n` + 'Учасник колегії - ' + judge['name'];
      });
    }
    this.taskForm.get('description').setValue(description);
  }

  changePriorityStatus(priority: string) {
    this.taskForm.get('priority').setValue(priority);
    this.priorityToggleButton = !this.priorityToggleButton;
  }
}
