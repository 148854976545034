import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimeFormatter {
  private readonly doublePointsPattern: RegExp = /^\d+:[0-5][0-9]$/; // 1:12
  private readonly hmPattern: RegExp = /^(\d+h\s?)?(([0-9]|[0-5][0-9])m)?$/; // 1h12m 1h
  private readonly doublePattern: RegExp = /^\d+(,\d+)?$/; // 1.2 1
  private readonly secondToHourDifference = 3600;

  constructor() {}

  isTimeValid(time: string): boolean {
    let isValid = false;
    [this.doublePointsPattern, this.hmPattern, this.doublePattern].forEach(x => {
      if (x.test(time)) {
        isValid = true;
      }
    });
    return isValid;
  }

  correctTimeFormatValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = this.isTimeValid(control.value);
      return valid ? null : { invalidTimeFormat: { value: control.value } };
      // return valid ? {'invalidTimeFormat': {value: control.value}} : null;
    };
  }

  convertStringToSeconds(timeString: string): number {
    if (this.doublePattern.test(timeString)) {
      return this.convertDoublePatternStringToSeconds(timeString);
    }
    if (this.hmPattern.test(timeString)) {
      return this.convertHmPatternStringToSeconds(timeString);
    }
    if (this.doublePointsPattern.test(timeString)) {
      return this.convertDoublePointsStringToSeconds(timeString);
    }
    return 0;
  }

  getNearestTime(dateTime: Date, intervalMinutes: number = 30): Date {
    const minutes = dateTime.getMinutes();
    dateTime.setMinutes(Math.floor(minutes / intervalMinutes) * intervalMinutes);
    dateTime.setSeconds(0);
    return dateTime;
  }

  convertSecondsToHoursQuantityString(duration: number): string {
    // const h = duration ? Math.floor(duration / 3600) : 0;
    // const m = duration ? Math.ceil((duration % 3600) / 60) : 0;

    return (duration / 3600).toFixed(2); // `${this.padTime(h)}.${this.padTime(m)}`;
  }

  convertSecondsToTableString(duration: number): string {
    const h = duration ? Math.floor(duration / 3600) : 0;
    const m = duration ? Math.ceil((duration % 3600) / 60) : 0;

    return `${this.padTime(h)}:${this.padTime(m)}`;
  }
  convertSecondsToString(duration: number): string {
    const h = duration ? Math.floor(duration / 3600) : 0;
    const m = duration ? Math.floor((duration % 3600) / 60) : 0;

    const s = duration ? Math.ceil(duration % 60) : 0;

    return `${this.padTime(h)}:${this.padTime(m)}:${this.padTime(s)}`;
  }

  padTime(t) {
    return t < 10 ? '0' + t : t;
  }

  // convertSecondsToModalString(duration: number): string {
  //   const time = this.splitSecondsToHoursAndMinutes(duration);
  //   return time.hours > 0 ? `${time.hours}h ${time.minutes}m` : `${time.minutes}m`;
  // }

  convertSecondsToHour(duration: number) {
    return this.splitSecondsToHoursAndMinutes(duration).hours;
  }

  simpleSecondsToHours(duration: number) {
    return duration / this.secondToHourDifference;
  }

  private splitSecondsToHoursAndMinutes(duration: number) {
    let hours = Math.floor(duration / this.secondToHourDifference);
    let minutes = Math.floor((duration % this.secondToHourDifference) / 60);
    const seconds = duration % 60;
    if (seconds > 1) {
      minutes = minutes + 1;
    }
    if (minutes === 60) {
      hours = hours + 1;
      minutes = 0;
    }
    const time = {
      hours,
      minutes
    };
    return time;
  }

  private convertDoublePointsStringToSeconds(inputString: string): number {
    const hours = +inputString.substring(0, inputString.indexOf(':'));
    const minutes = +inputString.substring(inputString.indexOf(':') + 1, inputString.length);
    const seconds = hours * this.secondToHourDifference + minutes * 60;
    return seconds;
  }

  private convertHmPatternStringToSeconds(inputString: string): number {
    const isHoursPresent = inputString.includes('h');
    const isMinutesPresent = inputString.includes('m');
    const hours = isHoursPresent ? +inputString.substring(0, inputString.indexOf('h')) : 0;
    const minutes = isMinutesPresent ? +inputString.substring(inputString.indexOf('h') + 1, inputString.indexOf('m')) : 0;
    const seconds = hours * this.secondToHourDifference + minutes * 60;
    return seconds;
  }

  private convertDoublePatternStringToSeconds(inputString: string): number {
    let seconds = 0;

    if (inputString && typeof inputString === 'string') {
      const formattedString = inputString.replace(',', '.');
      const hours = +formattedString;
      seconds = Math.floor(hours * this.secondToHourDifference);
    }

    return seconds;
  }
}
