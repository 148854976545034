import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ChangeColumnComponent } from '@app/commonComponents/change-column/change-column.component';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { TaskStatus } from '@app/enums/task-status.enum';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { MatterExtended } from '@app/models/matter-extended';
import { selectContactListForSelect } from '@app/modules/contacts/store/selectors/contact.selector';
import { LoadContactsForList } from '@app/modules/contacts/store/actions/contact.actions';
import { HelpersServices } from '@app/helpers/helpers.services';
import { KanbanComponent } from '@app/commonComponents/kanban/kanban.component';
import { Contact, RouteData, TaskExtended, IUser, SortingData } from '@app/models';
import { TasksFilters } from '@app/models/tasks-filters';
import { GetDocumentsByTaskSuccess } from '@app/store/actions/document.actions';
import { IAppState } from '@app/store/state/app.state';
import { TaskEffects } from '@app/store/effects/task.effects';
import { DeleteTask, GetTasks, UpdateTaskStatus, AddTaskFilters, ClearTasks } from '@app/store/actions/task.actions';
import { selectTaskPaginator, selectCurrentTasksState } from '@app/store/selectors/task.selectors';
import { generateString } from '@app/helpers';
import { WebsocketService } from '@app/websocket/websocket.service';
import { selectUserState } from '@app/auth/_store/selectors/auth.selector';
import { RoutesService } from '@app/helpers/routes.service';
import { TasksEventsModalComponent } from '@app/commonComponents/wide-modals/task-event-modal/t-e-modal.component';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    taskEffects: TaskEffects,
    private dialog: MatDialog,
    private helpersService: HelpersServices,
    private route: ActivatedRoute,
    private router: Router,
    private routesService: RoutesService,
    private store: Store<IAppState>,
    private translate: TranslateService,
    private wsService: WebsocketService
  ) {
    taskEffects.addTask$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.getFilteredData(this.filters));
    taskEffects.updateTask$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (!this.kanbanEnable) {
        this.getFilteredData(this.filters);
      }
    });
    taskEffects.getTasks$.subscribe(() => this.hideSpinner());
    taskEffects.deleteTask$.subscribe(() => {
      this.resultsLength--;
      this.hideSpinner();
    });
    this.initTables();
  }

  @Input() shouldSaveRoute: boolean;
  @Input() routesData: RouteData[];
  @Input() ifContact: Contact;
  @Input() ifMatter: MatterExtended;
  @Input() kanbanKey = 'main-tasks-kanban';
  @Input() kanbanEnable = true;
  @Input() ifUser: IUser;
  @Output() setScrollPositionEvent = new EventEmitter<number>();
  @Input() entityName = 'task';
  readonly spinnerName = 'tasksList';
  activeTab = 'upcoming';
  clients: Contact[];
  filters: TasksFilters = {};
  filterToggleButton = false;
  key: string;
  matters: MatterExtended[];
  tasksList: TaskExtended[];
  nameFilterUpdate$ = new Subject();
  resultsLength = 0;
  translater = this.translate.instant('common.filter');
  users: IUser[];
  sentColumns = [];
  topTasksOffset = 0;
  currentUser: IUser;
  loadKanban = true;
  loopItemsScroll = environment.loopItemsScroll;
  canbanEnable;
  loading = true;
  tableCreateBtns = [{click: () => this.openModal('task') , name: 'taskAndEvents.button.add.task'}, {click: () => this.openModal('event'), name: 'taskAndEvents.button.add.event'}];
  @ViewChild('changeColumn') private changeColumn: ChangeColumnComponent;
  @ViewChild('kanbanComponent', { static: false }) public kanbanComponent: KanbanComponent;
  showLoader = false;

  private localPage: number;
  private orderedByUser = false;
  private unsubscribe$ = new Subject<void>();

  private columns = [
    {
      draggable: false,
      disabled: true,
      name: 'common.type',
      visible: true,
      resizible: false,
      key: 'type',
      value: [],
      route: false,
      type: 'icon'
    },
    {
      draggable: false,
      disabled: true,
      name: 'table.fullName',
      visible: true,
      resizible: true,
      key: 'task-name',
      value: [],
      route: true,
      type: 'task'
    },
    {
      draggable: true,
      disabled: false,
      name: 'table.status',
      visible: true,
      resizible: true,
      key: 'status',
      value: [],
      route: false,
      type: 'status'
    },
    {
      draggable: true,
      disabled: false,
      name: 'table.priority',
      visible: true,
      resizible: true,
      key: 'priority',
      value: [],
      route: false,
      type: 'priority'
    },
    {
      draggable: true,
      disabled: false,
      name: 'common.date',
      visible: true,
      resizible: true,
      key: 'task-date',
      value: [],
      route: false,
      type: 'date'
    },
    {
      draggable: true,
      disabled: false,
      name: 'table.description',
      visible: true,
      resizible: true,
      key: 'description',
      value: [],
      route: false,
      type: 'string'
    },
    {
      draggable: true,
      disabled: false,
      name: 'table.taskAndEvents.responsible',
      visible: true,
      resizible: true,
      key: 'taskUsers',
      value: [],
      route: true,
      type: 'worker'
    },
    {
      draggable: true,
      disabled: false,
      name: 'matter',
      visible: true,
      resizible: true,
      key: 'inner-matter-name',
      value: [],
      route: true,
      type: 'matter'
    },
    {
      draggable: true,
      disabled: false,
      name: 'common.client',
      visible: false,
      resizible: true,
      key: 'matter-contact',
      value: [],
      route: true,
      type: 'contact'
    },
    {
      draggable: true,
      disabled: false,
      name: 'table.location',
      visible: false,
      resizible: true,
      key: 'location',
      value: [],
      route: false,
      type: 'string'
    },
    {
      draggable: true,
      disabled: false,
      name: 'table.timeEstimateTotal',
      visible: false,
      resizible: true,
      key: 'estimate-endDate',
      value: [],
      route: false,
      type: 'date'
    },
    {
      draggable: true,
      disabled: false,
      name: 'sidebar.correspondence',
      visible: false,
      resizible: true,
      key: 'correspondence',
      value: [],
      route: true,
      type: 'correspondence'
    }
  ];

  get page(): number {
    return this.localPage ? this.localPage : environment.defaultPage;
  }

  set page(value: number) {
    this.localPage = value;
  }

  get pageSize(): number {
    return this.changeColumn && this.changeColumn.paginator ? this.changeColumn.paginator.pageSize : environment.defaultItemsCount;
  }

  ngOnInit() {
    if (!this.ifMatter && !this.ifContact && !this.ifUser) {
      this.kanbanEnable = localStorage.getItem(this.kanbanKey) ? JSON.parse(localStorage.getItem(this.kanbanKey)) : true;
    }

    this.selectCurrentFiltersFromStore();

    this.getCurrentUser();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initTables();
      this.key = generateString(12);
      this.getUrlParams();
      this.selectTask();

      this.nameFilterUpdate$
        .pipe(debounceTime(environment.textSearchDebounce), distinctUntilChanged())
        .subscribe((query: string) => this.textFilter(query));
    }, 100);
  }

  ngOnDestroy() {
    sessionStorage.setItem(
      this.key,
      JSON.stringify({
        filters: this.filters,
        page: this.page,
        topOffset: this.topTasksOffset,
        orderedByUser: this.orderedByUser
      })
    );
    this.store.dispatch(new AddTaskFilters({}));
    if (this.changeColumn && this.changeColumn.paginator) {
      this.changeColumn.paginator.type = '';
    }
    this.helpersService.clearData();

    this.store.dispatch(new ClearTasks());
  }

  selectCurrentFiltersFromStore() {
    this.store
      .select(selectCurrentTasksState)
      .subscribe(taskState => {
        this.filters = taskState.filters;
        if (this.kanbanEnable) {
          this.filters.status = '';
          if (this.filters && !this.filters['startDate[after]'] && !this.filters.fromDashboard) {
            const dateAfter = new Date();
            dateAfter.setDate(dateAfter.getDate() - 14);
            this.filters['startDate[after]'] = CustomDateAdapter.convertToUniversalDateString(dateAfter);
          }
          if (this.filters && !this.filters['startDate[before]'] && !this.filters.fromDashboard) {
            const dateBefore = new Date();
            dateBefore.setDate(dateBefore.getDate() + 14);

            this.filters['startDate[before]'] = CustomDateAdapter.convertToUniversalDateString(dateBefore);
          }
        }
      })
      .unsubscribe();
  }

  openTaskInfo(task: TaskExtended) {
    this.router.navigate([`/tasks/card/${task.id}`]);
  }

  paginate() {
    this.page = 1;
    this.getFilteredData(this.filters);
    this.setQueryParams();
  }

  openModal(type = 'task', task?: TaskExtended, duplicate?: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      task,
      duplicate,
      contact: this.ifContact ? { id: this.ifContact.id } : null,
      matter: this.ifMatter,
      type
    };
    dialogConfig.panelClass = ['default-mat-dialog', `${type}s-modal`];
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(TasksEventsModalComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.store.dispatch(new GetDocumentsByTaskSuccess([]));
      });
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  editTask(task: TaskExtended, duplicate?: boolean) {
    const type = task.type;
    type[0].toUpperCase()
    this.openModal(type, task, duplicate);
  }

  duplicateTask(task: TaskExtended) {
    const type = task.type;
    type[0].toUpperCase();
    this.openModal(type, task, true);
  }

  deleteTask(task: TaskExtended) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'default-mat-dialog';

    dialogConfig.data = {
      yesButtonTxt: 'deleteYesButtonTxt',
      noButtonTxt: 'deleteNoButtonTxt',
      title: 'deleteTitleTxt',
      mainTxt: 'deleteTxt'
    };

    this.dialog
      .open(ConfirmModalComponent, dialogConfig)
      .afterClosed()
      .subscribe(result => {
        if (result === true) {
          this.store.dispatch(new DeleteTask(task.id));
        }
      });
  }

  clearSearchFilter() {
    this.textFilter('');
  }

  completeTask(task: TaskExtended) {
    this.store.dispatch(new UpdateTaskStatus({ id: task.id, status: TaskStatus.done }));
  }

  applyFilteredData(filters: TasksFilters) {
    this.getFilteredData(filters);
    this.setQueryParams();
  }

  countFilters() {
    let count = 0;
    if (this.filters['matter.contact.id']) {
      count++;
    }
    if (this.filters['taskUsers.user.id']) {
      count++;
    }
    if (this.filters.type) {
      count++;
    }
    if (this.filters['startDate[after]']) {
      count++;
    } else if (this.filters['endDate[before]']) {
      count++;
    }
    if (this.filters['matter.id']) {
      count++;
    }
    if (this.filters.status && this.filters.status !== 'all') {
      count++;
    }


    if (this.filters.name) {
      count++;
    }

    if (this.filters.priority) {
      count++;
    }

    return count;
  }

  onSortingChange(sortingData: SortingData) {
    switch (sortingData.columnKey) {
      case 'task-date':
        this.filters['order[endDate]'] = sortingData.sorting;
    }

    this.orderedByUser = true;
    this.applyFilteredData(this.filters);
  }

  private getUrlParams() {
    combineLatest([this.route.firstChild.params, this.route.firstChild.queryParams])
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(
        map(data => {
          if (!data[0] || !data[1]) {
            return;
          }

          if (data[1].page) {
            this.page = +data[1].page;
          }
          if (!data[1].key) {
            return;
          }
          this.key = data[1].key;
          const settings = JSON.parse(sessionStorage.getItem(data[1].key));
          if (settings?.filters) {
            this.filters = settings.filters;
            this.activeTab = data[0].status;
            this.filters.status = data[0].status;
          } else {
            this.filters.status = data[0].status;
          }

          if (settings?.page) {
            this.page = settings.page;
          }
          if (settings?.topOffset) {
            this.topTasksOffset = +settings.topOffset;
          }
          if (settings?.orderedByUser) {
            this.orderedByUser = settings.orderedByUser;
          }

          this.routesService.setUrl(this.router.url, !this.shouldSaveRoute);
          return data;
        })
      )
      .subscribe(param => {
        if (!param) {
          this.setQueryParams();
        } else {
          this.getFilteredData(this.filters);
        }
      });
  }

  private setQueryParams() {
    this.router.navigate([`${this.getPath()}/${this.activeTab}`], {
      queryParams: {
        key: this.key,
        page: this.page
      },
      replaceUrl: true
    });
  }

  private getPath(): string {
    return this.router.url
      .split('/')
      .slice(0, -1)
      .join('/');
  }

  private openSpinner() {
    this.loading = true;
    this.showLoader = true;
  }

  private hideSpinner() {
    this.loading = false;
    this.showLoader = false;
  }

  private confirmCloseModal(modalRef: MatDialogRef<any, any>) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  private scrollTasksTo() {
    this.setScrollPositionEvent?.emit(this.topTasksOffset);
    this.setScrollPositionEvent = null;
  }

  loadContacts() {
    this.store
      .select(selectContactListForSelect)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (!data) {
          this.store.dispatch(
            new LoadContactsForList({
              page: environment.defaultPage,
              itemsPerPage: environment.countItemInSelect
            })
          );
        }
        this.clients = data;
      });
  }

  changeKanbanEnable() {
    this.kanbanEnable = !this.kanbanEnable;
    this.loadKanban = this.kanbanEnable;
    this.tasksList = null;
    this.filters.clearFilters = false;
    if (!this.kanbanEnable) {
      this.filters.status = this.route.firstChild.snapshot.params.status;
      this.filters['startDate[after]'] = '';
      this.filters['startDate[before]'] = '';
      this.filters['endDate[before]'] = '';
    }

    this.getFilteredData();

    if (this.ifMatter || this.ifContact || this.ifUser) {
      const innerIds = localStorage.getItem(this.kanbanKey) ? JSON.parse(localStorage.getItem(this.kanbanKey)) : [];
      let findIdx;
      if (this.ifMatter) {
        findIdx = innerIds.findIndex(id => id === this.ifMatter.id);
      } else if (this.ifContact) {
        findIdx = innerIds.findIndex(id => id === this.ifContact.id);
      } else if (this.ifUser) {
        findIdx = innerIds.findIndex(id => id === this.ifUser.id);
      }
      // якщо в локал стор є ід сутності і канбан виключили
      if (findIdx !== -1 && !this.kanbanEnable) {
        innerIds.splice(findIdx, 1);
      } else if (findIdx === -1 && this.kanbanEnable) {
        innerIds.push(this.ifMatter ? this.ifMatter.id : this.ifContact ? this.ifContact.id : this.ifUser ? this.ifUser.id : '');
      }
      localStorage.setItem(this.kanbanKey, JSON.stringify(innerIds));
    } else {
      localStorage.setItem(this.kanbanKey, JSON.stringify(this.kanbanEnable));
    }
  }

  private resetPaginator() {
    if (!this.kanbanEnable) {
      this.page = 1;
      this.changeColumn.paginator.pager.currentPage = 1;
    }
  }

  private initTables() {
    const entityTable = `${this.entityName}Table`;
    const currentWidthsColumnsTable = window.localStorage.getItem(entityTable) ? JSON.parse(window.localStorage.getItem(entityTable)) : {};

    const currentColumnsTable = window.localStorage.getItem(`${this.entityName}SettingTable`)
      ? JSON.parse(window.localStorage.getItem(`${this.entityName}SettingTable`))
      : {};
    this.sentColumns = this.columns;
    if (Object.keys(currentColumnsTable).length === 0) {
      const currentTable = document.getElementsByClassName('table-section-template')[0] as HTMLElement;
      if (currentTable) {
        for (const col of this.sentColumns) {
          col.width = currentTable?.offsetWidth / this.sentColumns.length;
        }
      }
    } else {
      let posIdx = 0;
      for (const key in currentColumnsTable) {
        if (currentColumnsTable.hasOwnProperty(key)) {
          const idx = this.sentColumns.findIndex(item => item.key === key);
          if (idx !== -1) {
            this.sentColumns[idx].visible = currentColumnsTable[key];
            this.sentColumns[idx].position = posIdx;
          }
          posIdx++;
        }
      }
    }

    if (Object.keys(currentWidthsColumnsTable).length !== 0) {
      for (const key in currentWidthsColumnsTable) {
        if (currentWidthsColumnsTable.hasOwnProperty(key)) {
          const idx = this.sentColumns.findIndex(item => item.key === key.replace(this.entityName, ''));
          if (idx !== -1) {
            this.sentColumns[idx].width = currentWidthsColumnsTable[key];
          }
        }
      }
    }
    this.sentColumns.sort((a, b) => a.position - b.position);
  }

  private selectTask() {
    setTimeout(() => {
      this.store
        .select(selectTaskPaginator)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(paginator => {
          if (!paginator?.member) {
            return;
          }
          this.tasksList = paginator.member;
          this.resultsLength = paginator.totalItems;
          if (this.kanbanEnable && this.kanbanComponent && this.tasksList && this.loadKanban) {
            this.loadKanban = false;
            this.kanbanComponent.sortingData(this.tasksList);
          }
          if (this.changeColumn && this.changeColumn.paginator) {
            this.changeColumn.paginator.type = 'task';
            setTimeout(() => {
              this.changeColumn.paginator.pager.currentPage = this.page;
              this.changeColumn.paginator.pageIndex = this.page - 1;
              this.scrollTasksTo();
            });
          }
          if (this.countFilters() > 1) {
            this.translater = this.translate.instant('taskAndEvents.filter.filters');
          } else {
            this.translater = this.translate.instant('common.filter');
          }
          if (this.changeColumn) {
            this.changeColumn.restList = this.tasksList;
            this.changeColumn.columns = this.sentColumns;
            this.changeColumn.initTableData();
          }
        });
    });
  }

  getCurrentUser() {
    this.store.select(selectUserState).subscribe(res => {
      this.currentUser = res;
      if (res) {
        this.checkSocketTasks();
      }
    });
  }
  checkSocketTasks() {
    this.wsService.centrifugo.subscribe(`task#${this.currentUser.id}`, (message: { seq: number; data: any }) => {
      const dataTask = { type: message.data.type, task: JSON.parse(message.data.task) };
      const idx = this.tasksList.findIndex(item => +item.id === +dataTask.task.id);
      if (idx !== -1) {
        this.tasksList[idx] = dataTask.task;
      } else if (this.checkIfNewTaskRefersToMatter(dataTask)) {
        this.tasksList.push(dataTask.task);
        this.changeColumn?.initTableData()
      }
      if (this.kanbanComponent && this.tasksList && this.checkIfNewTaskRefersToMatter(dataTask)) {
        if (!dataTask.task.orderCanban) {
          dataTask.task.orderCanban = dataTask.task.id * 100;
        }
        if (dataTask?.task?.taskUsers && !dataTask.task.taskUsers.length) {
          const users = [];
          for (const key in dataTask.task.taskUsers) {
            if (Object.prototype.hasOwnProperty.call(dataTask.task.taskUsers, key)) {
              users.push(dataTask.task.taskUsers[key]);
            }
          }
          dataTask.task.taskUsers = users;
        }
        this.kanbanComponent.updatedItem(dataTask);
        // this.kanbanComponent.sortingData(this.tasksList);
      }
    });
  }

  checkIfNewTaskRefersToMatter(dataTask: { type: string; task: TaskExtended }): boolean {
    return (
      dataTask.task &&
      (!this.filters['matter.id'] || dataTask.task.matter.id === this.filters['matter.id']) &&
      (!this.filters['matter.contact.id'] || this.checkContact(dataTask.task.matter.contact, this.filters['matter.contact.id'])) &&
      (!this.filters?.status || this.checkStatus(dataTask.task, dataTask.task.status, this.filters?.status)) &&
      (!this.filters?.type || dataTask.task.type === this.filters?.type) &&
      this.checkDate(dataTask.task, this.filters)
    )
  }
  checkDate(task, filters) {
    if (!filters['startDate[after]'] && !filters['startDate[before]']) {
      return true;
    } else if (filters['startDate[after]'] && !filters['startDate[before]']) {
      // якщо початкова дата є а кінцевої нема
      if (new Date(filters['startDate[after]']) <= new Date(task.endDate)) {
        return true;
      } else {
        return false;
      }
    } else if (!filters['startDate[after]'] && filters['startDate[before]']) {
      // якщо кінцева дата є а початкової нема
      if (new Date(filters['startDate[before]']) >= new Date(task.startDate)) {
        return true;
      } else {
        return false;
      }
    } else if (filters['startDate[after]'] && filters['startDate[before]']) {
      // якщо кінцева дата є і початкова є
      if (
        new Date(filters['startDate[after]']) <= new Date(task.endDate) &&
        new Date(filters['startDate[before]']) >= new Date(task.startDate)
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  checkContact(taskContact, filterContact) {
    if (!taskContact && filterContact) {
      return false;
    } else if (taskContact && taskContact.id === filterContact) {
      return true;
    } else if (!taskContact && !filterContact) {
      return true;
    }
  }

  checkStatus(task, taskStatus, filterStatus) {
    // якщо філтр upcoming або overdue
    if (filterStatus && (filterStatus === 'upcoming' || filterStatus === 'overdue')) {
      // і в таска статус new або in_work
      if (taskStatus === 'new' || taskStatus === 'in_work') {
        // якщо overdue то ще плюс зрівнюємо кінцеву дату
        if (filterStatus === 'overdue') {
          if (new Date(task.endDate) <= new Date()) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } else if (filterStatus && filterStatus === 'all') {
      return true;
    } else if (filterStatus === taskStatus) {
      return true;
    }
    return false;
  }

  public textFilter(value: string) {
    if (this.filters.name !== value) {
      this.resetPaginator();
      this.getFilteredData({ ...this.filters, name: value });
      this.setQueryParams();
    }
  }

  private getFilteredData(tasksFilter?: TasksFilters, silent = false) {
    this.filterToggleButton = false;
    if (tasksFilter) {
      this.filters = tasksFilter;
    }

    if (this.filters && this.filters['startDate[after]']) {
      this.filters['startDate[after]'] = CustomDateAdapter.convertToUniversalDateString(new Date(this.filters['startDate[after]']));
    }
    if (this.filters && this.filters['startDate[before]']) {
      this.filters['startDate[before]'] = CustomDateAdapter.convertToUniversalDateString(new Date(this.filters['startDate[before]']));
    }
    if (this.ifContact && !this.ifMatter) {
      this.filters['matter.contact.id'] = this.ifContact.id;
    }
    if (this.ifMatter) {
      this.filters['matter.id'] = this.ifMatter.id;
    }
    if (this.ifUser) {
      this.filters['taskUsers.user.id'] = this.ifUser.id;
    }

    if (this.filters && this.filters.status === 'all') {
      this.filters.status = null;
      if (!this.orderedByUser || this.canbanEnable) {
        this.filters['order[endDate]'] = 'desc';
      }
    } else if (this.filters && this.filters.status === 'done') {
      if (!this.orderedByUser || this.canbanEnable) {
        this.filters['order[endDate]'] = 'desc';
      }
    } else {
      if (!this.orderedByUser || this.canbanEnable) {
        this.filters['order[endDate]'] = null;
      }
    }

    this.setSortingDataToColumns();

    if (!silent) {
      this.openSpinner();
    }
    this.activeTab = this.route.snapshot.firstChild.url[0].path ?? TaskStatus.all;
    if (this.kanbanEnable) {
      this.filters.status = '';
      if (!this.filters.fromDashboard && !this.filters.clearFilters) {
        if (this.filters && !this.filters['startDate[after]']) {
          const dateAfter = new Date();
          dateAfter.setDate(dateAfter.getDate() - 14);
          this.filters['startDate[after]'] = CustomDateAdapter.convertToUniversalDateString(dateAfter);
        }
        if (this.filters && !this.filters['startDate[before]']) {
          const dateBefore = new Date();
          dateBefore.setDate(dateBefore.getDate() + 14);
          this.filters['startDate[before]'] = CustomDateAdapter.convertToUniversalDateString(dateBefore);
        }
      }
      this.loadKanban = true;
      window.sessionStorage.setItem(this.key, JSON.stringify({ filters: this.filters }));
      this.store.dispatch(new AddTaskFilters(this.filters));
      this.store.dispatch(new GetTasks({ page: 1, itemsPerPage: 1000, filters: this.filters }));
    } else {
      this.activeTab = tasksFilter?.status ?? 'all';
      window.sessionStorage.setItem(this.key, JSON.stringify({ filters: this.filters }));
      this.initTables();
      this.store.dispatch(new GetTasks({ page: this.page, itemsPerPage: this.pageSize, filters: this.filters }));
    }
  }

  private setSortingDataToColumns() {
    const column = this.sentColumns.find(c => c.key === 'task-date') ?? this.columns.find(c => c.key === 'task-date');
    if (column) {
      column.sortedBy = this.filters['order[endDate]'] ?? 'asc';
    }
  }
}
