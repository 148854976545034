// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.pagination-footer {
  height: 50px;
  display: none;
}
div.pagination-footer .footer-pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/commonComponents/pagination/pagination.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,YAAA;AAEJ","sourcesContent":["div.pagination-footer {\n  height: 50px;\n  display: none;\n  .footer-pagination-wrapper {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    height: 100%;\n    //border-top: 1px solid #F4F4F4;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
