import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { selectCurrentUser } from '@app/auth/_store/selectors/auth.selector';
import { BillStatus } from '@app/enums/bills/bill-status';
import { BillType } from '@app/enums/bills/bill-type';
import { SwitchNotificationsService } from '@app/helpers';
import { IUser, SortingData, TaskExtended } from '@app/models';
import { BankAccountExtended } from '@app/models/bank-account-extended';
import { Bill } from '@app/models/bill';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { IAppState } from '@app/store/state/app.state';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PaginationComponent } from '../pagination/pagination.component';
import { RowMenuComponent } from './row-menu/row-menu.component';
import { TableService } from './services/table.service';
import { ITrash } from '@app/models/interfaces/trash-interface';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-change-column',
  templateUrl: './change-column.component.html',
  styleUrls: ['./change-column.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('readMsg', [
      // ...
      state(
        'open',
        style({
          // background: '#f6f9fa',
          'border-left-color': '#1252af'
        })
      ),
      state(
        'closed',
        style({
          // backgroundColor: '#ffffff',
          'border-left-color': '#eaeaea'
        })
      ),
      transition('* => closed', [animate('1s')]),
      transition('* => open', [animate('1s')])
    ])
  ],
  host: {
    '(mousemove)': 'mouseMoveHandler($event)',
    '(mouseup)': 'mouseUpHandler()'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeColumnComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Output() deleteItem = new EventEmitter<number>();
  @Output() editItem = new EventEmitter<any>();
  @Output() onPinMatter = new EventEmitter<any>();
  @Output() duplicateItem = new EventEmitter<TaskExtended>();
  @Output() viewItem = new EventEmitter<any>();
  @Output() sortingChange = new EventEmitter<SortingData>();
  @Output() approve = new EventEmitter<Bill>();
  @Output() paymentRegistrationModal = new EventEmitter<Bill>();
  @Output() completeTask = new EventEmitter<TaskExtended>();
  @Output() paginate = new EventEmitter();
  @Output() page = new EventEmitter<number>();
  @Output() restoreItem = new EventEmitter<ITrash>();
  @Output() permissionModal = new EventEmitter<IUser>();
  @Output() permissionDeactivate = new EventEmitter<number>();
  @Output() permissionActivate = new EventEmitter<number>();
  @Output() permissionReSendMail = new EventEmitter<number>();
  @Output() changeCheckedList = new EventEmitter<number>();
  @Input() restList = [];
  @Input() columns = [];
  @Input() entity: string;
  @Input() bankAccount: BankAccountExtended;
  @Input() resultsLength;
  @Input() virtualHeight;
  @Input() search;
  @Input() loading = true;
  @Input() initSettingsTable = true;
  @Input() hideRowMenu = true;
  @Input() createBtns;
  @Input() customNotFoundTxtTitle;
  @Input() customNotFoundTxtDescription;
  showLoader = false;

  showTableHead = false;
  @ViewChild('paginator') public paginator: PaginationComponent;

  constructor(
    private router: Router,
    private contactService: ContactService,
    private translate: TranslateService,
    private store: Store<IAppState>,
    private tableService: TableService,
    private cdRef: ChangeDetectorRef,
    public switchNotificService: SwitchNotificationsService
  ) {
  }

  spinnerName = 'columns';

  public currentUser: IUser;

  visibleSettingColumns = [];

  settingMenu = false;
  loopItemsScroll = environment.loopItemsScroll;

  // для активностей
  @Input() restTotal;
  @Input() defaultCurrency;

  // bill
  @Input() totalBill;
  @Input() totalDebtBill;
  @Input() totalAmountBalance;

  @ViewChild('rowMenu', { static: false }) public rowMenu: RowMenuComponent;

  localPage;

  @ViewChild('itemsContainer', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChild('item', { read: TemplateRef }) template: TemplateRef<any>;
  startX;
  startWidth;
  handle;
  table;
  pressed = false;

  ngOnInit() {
    this.getCurrentUser();
    this.visibleSettingColumns = JSON.parse(JSON.stringify(this.columns));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initResize();
      this.initTableData();
      if (this.visibleTotalByEntity(['communicationDocument', 'communicationBill'])) {
        this.initTableData();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.restList && (this.restList?.length || this.restList?.length === 0)) {
      setTimeout(() => {
        this.showTableHead = true;
      });
    }
  }

  ngOnDestroy() {
    document.onmousemove = null;
    document.onmouseup = null;
  }

  // початок логіка для ресайзу таблиці
  mouseDownHandler(event) {
    this.handle = event.target.offsetParent;
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = this.handle.offsetWidth;

    this.table = this.handle.closest('.table-resizable');
    this.table.classList.add('resizing');
  }

  mouseUpHandler() {
    if (this.pressed) {
      this.table.classList.remove('resizing');
      this.pressed = false;
      this.saveWidthToStorage();
    }
  }

  mouseMoveHandler(event) {
    if (this.pressed) {
      this.handle.style['min-width'] = this.startWidth + (event.pageX - this.startX) + 'px';
      this.handle.style['width'] = this.startWidth + (event.pageX - this.startX) + 'px';
    }
  }

  // кінець логіки для ресайзу


  changeVisibleItem(e, idx) {
    this.visibleSettingColumns[idx].visible = !this.visibleSettingColumns[idx].visible;
  }

  initTableData() {
    this.tableService.buildVisibleDataForRow(this.columns, this.restList, this.entity, this.bankAccount);
    this.buildData();
    this.initResize();
  }

  private buildData() {
    const start = 0;
    const end = this.restList ? this.restList.length : 0;
    this.container.clear();
    for (let n = start; n < end; n++) {
      this.container.createEmbeddedView(this.template, {
        item: this.restList[n],
        index: n
      });
    }
    this.cdRef.markForCheck();
  }

  saveCurrentTable() {
    const entityTable = {};
    const tableHs = document.getElementsByClassName(`table-h`);

    Array.prototype.forEach.call(tableHs, th => {
      if (th?.id) {
        entityTable[th.id] = th.offsetWidth;
      }
    });
    window.localStorage.setItem(`${this.entity}Table`, JSON.stringify(entityTable));

    this.settingMenu = false;
    this.columns = this.visibleSettingColumns;
    this.saveSettingsToLocalStore();

    this.initTableData();
    this.overlayClick();
    // setTimeout(() => {
    // this.initResize();
    // });
  }

  initResize(): void {
    const entityTable = `${this.entity}Table`;
    const currentTable = document.getElementsByClassName('main-table-container')[0] as HTMLElement;
    const currentColumnsTable = window.localStorage.getItem(entityTable) ? JSON.parse(window.localStorage.getItem(entityTable)) : {};
    let countvisibleSettingColumns = 0;
    for (const visColumn of this.columns) {
      if (visColumn.resizible && visColumn.visible) {
        countvisibleSettingColumns++;
      }
    }
    if (Object.keys(currentColumnsTable).length === 0) {
      for (const visColumn of this.columns) {
        if (visColumn.resizible && visColumn.visible && currentTable) {
          currentColumnsTable[`${this.entity}${visColumn.key}`] = currentTable.offsetWidth / countvisibleSettingColumns;
        }
      }
      window.localStorage.setItem(`${this.entity}Table`, JSON.stringify(currentColumnsTable));
    }

    Array.prototype.forEach.call(document.querySelectorAll(`.main-table .table-h`), th => {
      for (const key in currentColumnsTable) {
        if (th.id === key) {
          th.style['min-width'] = currentColumnsTable[key] + 'px';
          th.style['width'] = currentColumnsTable[key] + 'px';
        }
      }
    });
    this.cdRef.markForCheck();
  }

  saveWidthToStorage() {
    const nameTable = {};
    const tableHs = document.getElementsByClassName(`table-h`);
    Array.prototype.forEach.call(tableHs, th => {
      if (th?.id) {
        nameTable[th.id] = th.offsetWidth;
      }
    });
    window.localStorage.setItem(`${this.entity}Table`, JSON.stringify(nameTable));
  };

  goToContact(contact) {
    this.contactService.getById(contact.id)
      .pipe(first())
      .subscribe(res => {
        this.hideSpinner();
        if (res.id) {
          this.router.navigateByUrl(`/contact/${contact.id}`);
        }
      });
  }

  goToWorker(user) {
    this.contactService.getUserById(user.id).subscribe(res => {
      this.hideSpinner();
      if (res.id) {
        this.router.navigateByUrl(`/worker/${user.id}`);
      }
    });
  }

  goToEntity(col, item) {
    this.openSpinner();
    if (!col.id) {
      this.hideSpinner();
      return;
    }
    switch (col.type) {
      case 'matter':
        this.hideSpinner();
        this.router.navigateByUrl(`/${col.type}/${col.id}`);
        break;
      case 'contact':
        this.goToContact(col);
        break;
      case 'worker':
        this.goToWorker(col);
        break;
      case 'task':
        this.hideSpinner();
        this.router.navigateByUrl(`/tasks/card/${col.id}`);
        break;
      case 'bill': {
        this.hideSpinner();
        this.router.navigate([`/bills/card/${col.id}`]);
        break;
      }
      case 'document': {
        this.hideSpinner();
        this.router.navigate([`/documents/card/${col.id}`]);
        break;
      }
      case 'contract': {
        this.hideSpinner();
        this.router.navigateByUrl(`/create-contract/card/${col.id}?tab=details`);
        break;
      }
      case 'account': {
        this.hideSpinner();
        this.router.navigateByUrl(`bank-accounts/card/${col.id}`);
        break;
      }
      case 'correspondence': {
        this.hideSpinner();
        this.router.navigateByUrl(`correspondence/card/${col.id}`);
        break;
      }
      case 'activity': {
        this.hideSpinner();
        if (item.type === 'expense') {
          this.router.navigateByUrl(`/activities/card/${col.id}?type=expenses`);
        } else {
          this.router.navigateByUrl(`/activities/card/${col.id}?type=time_entries`);
        }
        break;
      }
      case 'communication': {
        this.hideSpinner();
        this.onViewItem(item);
        break;
      }
      case 'transfer': {
        this.hideSpinner();
        this.router.navigateByUrl(`/bank-accounts/card/${col.id}`);
        break;
      }
      case 'automation': {
        this.hideSpinner();
        this.router.navigateByUrl(`/settings/automation/${col.id}`);
        break;
      }
      default:
        this.hideSpinner();
    }
  }

  onViewItem(item) {
    this.viewItem.emit(item);
  }

  onApprove(item) {
    this.approve.emit(item);
  }

  onPaymentRegistrationModal(item) {
    this.paymentRegistrationModal.emit(item);
  }

  markAsComplete(task) {
    this.completeTask.emit(task);
  }

  onEditItem(item) {
    this.editItem.emit(item);
  }

  onPintem(item) {
    this.onPinMatter.emit(item);
  }

  onDuplicateItem(item) {
    this.duplicateItem.emit(item);
  }

  onDeleteItem(item) {
    this.deleteItem.emit(item);
  }

  openSpinner() {
    this.loading = true;
    this.showLoader = true;
  }

  hideSpinner() {
    this.showLoader = false;
    this.loading = false;
  }

  openSettingMenu() {
    const tableSetting = document.querySelectorAll('.table-setting-container')[0] as HTMLElement;
    const tableWrapper = document.querySelectorAll('.change-table-wrapper')[0] as HTMLElement;

    this.settingMenu = !this.settingMenu;
    if (!this.settingMenu) {
      this.overlayClick();
      tableWrapper.style['z-index'] = 100;
    }

    Array.prototype.forEach.call(document.querySelectorAll('.setting-content'), item => {
      item.classList.remove('active');
    });
    Array.prototype.forEach.call(document.querySelectorAll('.dropbtn'), item => {
      item.classList.remove('active');
    });


    if (tableSetting && tableWrapper) {
      tableSetting.style['z-index'] = 1010;
      if (this.settingMenu) {
        tableWrapper.style['z-index'] = 9999;
      }
    }
    this.visibleSettingColumns = JSON.parse(JSON.stringify(this.columns));

  }

  saveSettingsToLocalStore() {
    const entityTable = {};
    Array.prototype.forEach.call(document.querySelectorAll('#setting-content > ul > li > div > input'), item => {
      if (item.name) {
        entityTable[item.name] = item.checked;
      }
    });
    window.localStorage.setItem(`${this.entity}SettingTable`, JSON.stringify(entityTable));
  }

  public getQuantityTotal() {
    return this.restTotal?.duration;
  }

  public getRestTotal() {
    let restTotal = 0;
    if (this.restTotal) {
      restTotal = this.restTotal.amount;
    }
    return this.tableService.getBalanceVal(restTotal);
  }

  isDraft(bill: Bill) {
    return bill && bill.status === BillStatus.draft;
  }

  isPrepay(bill: Bill) {
    return bill && bill.typePay === BillType.prepaid;
  }

  getCurrentUser() {
    this.store.select(selectCurrentUser).subscribe(user => {
      this.currentUser = user;
    });
  }

  overlayClick() {
    this.settingMenu = false;
    Array.prototype.forEach.call(document.querySelectorAll('.table-setting-container'), item => {
      item.style['z-index'] = 4;
    });
    const tableWrapper = document.querySelectorAll('.change-table-wrapper')[0] as HTMLElement;
    tableWrapper.style['z-index'] = 100;
  }

  sortByColumn(columnKey) {

    if (!this.checkSortedColumn(columnKey)) {
      return;
    }

    this.columns.forEach(c => {
      if (c.sortedBy && c.key !== columnKey) {
        c.sortedBy = true;
      }
    });

    this.columns.forEach(c => {
      if (c.key !== columnKey) {
        return;
      }

      if (c.sortedBy === 'desc') {
        c.sortedBy = 'asc';
      } else if (c.sortedBy === 'asc') {
        c.sortedBy = '-';
      } else {
        c.sortedBy = 'desc';
      }

      this.sortingChange.emit({
        columnKey,
        sorting: c.sortedBy
      });
    });
  }

  checkShowEmblem(item, col) {
    return col.key === 'matter-name' && (!item['resetMonthlyBudget'] ? 'reset' : '') + ' ' + (item['subscribedCourtCase'] ? 'court' : '');
  }

  checkSortedColumn(columnKey) {
    if (!columnKey) {
      return;
    }
    if (!this.columns.find(c => c.key === columnKey)?.sortedBy) {
      return;
    }
    return true;
  }

  dragging = false;

  drop(event: CdkDragDrop<any[]>) {
    this.dragging = false;
    moveItemInArray(this.visibleSettingColumns, event.previousIndex, event.currentIndex);
  }
  dragStart() {
    this.dragging = true;
  }

  get Page(): number {
    return this.localPage ? this.localPage : environment.defaultPage;
  }

  set Page(value: number) {
    this.page.emit(value);
  }

  checkIsNew(item) {
    return (
      this.visibleTotalByEntity(['communication']) &&
      !item['lastComment']?.isReaded &&
      !(item['lastComment']?.publishedBy?.id === this.currentUser?.id || item['lastComment']?.publishedBy?.type === 'user')
    );
  }

  openToolTip(e, type, show, value?) {
    const tooltip = document.querySelector('.table-tooltip') as HTMLElement;
    let visibleToolTip = false;
    if (value?.trim()) {
      const span = document.createElement('span');
      span.innerHTML = value.trim();
      if (span.lastChild['dataset']?.text) {
        visibleToolTip = true;
        tooltip.innerHTML = span.lastChild['dataset']?.text;
      }
    }
    switch (type) {
      case 'resetBudget':
        tooltip.innerHTML = this.translate.instant('table.resetBudgetToolTip');
        visibleToolTip = true;
        break;
      case 'monitoring':
        tooltip.innerHTML = this.translate.instant('table.resetMonitoringToolTip');
        visibleToolTip = true;
        break;
      case 'unbilled':
        tooltip.innerHTML = this.translate.instant('time.nonBillable');
        visibleToolTip = true;
        break;
      case 'signing':
        tooltip.innerHTML = this.translate.instant('table.signingToolTip');
        visibleToolTip = true;
        break;
      case 'setting':
        if (value) {
          e = e?.getBoundingClientRect();
          tooltip.innerHTML = this.translate.instant(value);
          if (tooltip.getBoundingClientRect().height > 50) {
            visibleToolTip = true;
          }
          tooltip.style.width = '167px';
        }

        break;
    }

    if (tooltip?.style && show && visibleToolTip) {
      tooltip.style.top = `${e.y - (tooltip.getBoundingClientRect().height + 10)}px`;
      tooltip.style.left = `${e.x + 20}px`;
      tooltip.style.opacity = '1';
      tooltip.style.zIndex = '10000';
    } else if (tooltip?.style) {
      tooltip.style.opacity = '0';
      tooltip.style.zIndex = '-10';

    }
  }

  visibleTotalByEntity(entities: string[]) {
    return entities.find(str => this.entity.includes(str)) && this.entity !== 'billPayments';
  }
}
