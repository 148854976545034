import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ChangePassword } from '@app/modules/settings/store/actions/team-member.actions';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { Subscription } from 'rxjs';
import { TeamMemberEffects } from '@app/modules/settings/store/effects/team-member.effects';

@Component({
  selector: 'app-password-change-modal',
  templateUrl: './password-change-modal.component.html',
  styleUrls: ['./password-change-modal.component.scss']
})
export class PasswordChangeModalComponent implements OnInit, OnDestroy {
  passwordChangeForm: UntypedFormGroup;
  submittedChangePassForm = false;

  private createdSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PasswordChangeModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private teamMemberEffects: TeamMemberEffects,
    private store: Store<IAppState>
  ) {}

  ngOnInit() {
    this.createForm();
    this.createdSubscription = this.subscribeToEffects();
  }

  ngOnDestroy() {
    this.createdSubscription.unsubscribe();
  }

  onSubmit() {
    this.submittedChangePassForm = true;
    this.passwordChangeForm.updateValueAndValidity();
    if (!this.passwordChangeForm.valid) {
      return;
    }

    delete this.passwordChangeForm.value.confirmNewPassword;

    this.store.dispatch(new ChangePassword(this.passwordChangeForm.value));
  }

  checkPasswords(group: UntypedFormGroup): ValidationErrors | null {
    const pass = group.get('password');
    const confirmPass = group.get('confirmNewPassword');
    return pass && confirmPass && pass.value === confirmPass.value ? null : { notSame: true };
  }

  closeModal() {
    this.dialogRef.close();
  }

  private createForm() {
    this.passwordChangeForm = this.formBuilder.group(
      {
        oldPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[0-9a-zA-Z_\\-~!@#\\$%\\^&]{6,}$')
          ]
        ],
        confirmNewPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]]
      },
      { validator: this.checkPasswords }
    );
  }

  private subscribeToEffects(): Subscription {
    const subscription = new Subscription();
    const actionAfterUpdate = worker => {
      this.closeModal();
    };

    subscription.add(this.teamMemberEffects.changePassword$.subscribe(actionAfterUpdate));

    return subscription;
  }

  get f(): UntypedFormGroup {
    return this.passwordChangeForm;
  }

  get p() {
    return this.passwordChangeForm.controls;
  }
}
