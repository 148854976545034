import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatterExtended } from '@app/models/matter-extended';
import { Router } from '@angular/router';
import { IConfirmModalData } from '@app/models/confirm-modal-data';
import { CustomDateAdapter } from '../../helpers/custom-date-adapter';
import { AppSettingsService } from '../../helpers/app-settings.service';
@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmModalData,
    private router: Router,
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    private appSettingsService: AppSettingsService,
  ) {}

  public locale$ = this.appSettingsService.getLang;
  public yesButtonTxt: string;
  public noButtonTxt: string;
  public title: string;
  public mainTxt: string;
  public modalTyle: boolean;
  public noBtnStyle: boolean;
  public disableYesButton: boolean;
  public disableNoButton: boolean;
  dateFormat = CustomDateAdapter.dateFormat;
  dateTransfer = new Date();
  matters: MatterExtended;
  formSubmitted = false;

  @HostListener('click', ['$event'])
  public onClick(event) {
    if (event.target.tagName === 'A') {
      this.router.navigate([event.target.getAttribute('href')]);
      if (!this.data.eventModal) {
        this.dialogRef.close(false);
      } else {
        this.dialogRef.close('close');
      }
      event.preventDefault();
    } else {
      return;
    }
  }

  ngOnInit() {
    this.yesButtonTxt = this.data.yesButtonTxt || 'Yes';
    this.noButtonTxt = this.data.noButtonTxt || 'No';
    this.title = this.data.title || 'Confirm';
    this.mainTxt = this.data.mainTxt || 'Confirm this action?';
    this.modalTyle = this.data.modalTyle === 'blue';
    this.noBtnStyle = this.data.noBtnStyle === 'blue';
    this.disableYesButton = !this.data.disableYesButton;
    this.disableNoButton = !this.data.disableNoButton;

    this.matters = this.data.matters;
  }

  onClickOk() {
    this.formSubmitted = true;
    if(this.data.showDateInput && !this.dateTransfer) {
      return;
    }
    this.dialogRef.close(this.data.showDateInput ? {action: true, date: this.dateTransfer} : true);
  }

  onClickCancel() {
    this.dialogRef.close(false);
  }
}
