import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, first, switchMap, take } from 'rxjs/operators';
import { AppSettingsService } from '@app/helpers/app-settings.service';
import { AuthService } from '@app/auth/_services/auth.service';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { Logout } from '@app/auth/_store/actions/auth.actions';
import { Router } from '@angular/router';
import { WebsocketService } from '@app/websocket/websocket.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseBodyAuth } from '@app/models';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private AUTH_HEADER = 'Authorization';
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<ResponseBodyAuth> = new BehaviorSubject<ResponseBodyAuth>(null);

  constructor(
    private authenticationService: AuthService,
    private store: Store<IAppState>,
    private router: Router,
    private appSettingsService: AppSettingsService,
    private wsService: WebsocketService,
    private toastrService: ToastrService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method === 'JSONP') {
      // Якщо це JSONP, просто пропускаємо його далі без будь-яких модифікацій
      return next.handle(req);
    }


    return this.appSettingsService.getLang.pipe(
      first(),
      switchMap(lang => {

        req = req.clone({
          setHeaders: {
            'X-API-Language': lang,
            Accept: 'application/ld+json',
          },
        });


        if (req.method === 'POST') {
          req = this.changeToHydra(req);
          req = req.clone({
            setHeaders: {
              'Content-Type': 'application/ld+json',
            },
          });
        } else if (req.method === 'PATCH') {
          req = this.changeToHydra(req);
          req = req.clone({
            setHeaders: {
              'Content-Type': 'application/merge-patch+json',
            },
          });
        }


        req = this.addAuthenticationToken(req);


        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status >= 400 && req.url.includes('jwt/refresh')) {
              this.store.dispatch(new Logout());
              this.router.navigateByUrl('/auth/login');
              return throwError(error);
            }

            if (error.status === 401 && error.url.includes('credentials')) {
              this.wsService.webSocketDisconnect();
              this.wsService.webSocketConnect();
              return throwError(error);
            }

            if (error && error.status === 401 && !req.url.includes('jwt/refresh') && this.authenticationService.getRefreshToken()) {

              if (this.refreshTokenInProgress) {
                return this.refreshTokenSubject.pipe(
                  filter(result => !!result),
                  take(1),
                  switchMap(() => next.handle(this.addAuthenticationToken(req))),
                );
              } else {
                this.refreshTokenInProgress = true;
                this.refreshTokenSubject.next(null);

                return this.refreshAccessToken().pipe(
                  switchMap((result) => {
                    this.authenticationService.storeTokens(result);
                    this.refreshTokenSubject.next(result);

                    return next.handle(this.addAuthenticationToken(req));
                  }),
                  finalize(() => {
                    this.refreshTokenInProgress = false;
                  }),
                );
              }
            } else {
              if (req.url.includes('jwt/refresh')) {
                this.store.dispatch(new Logout());
                this.router.navigateByUrl('/auth/login');
              } else {
                if (error.status === 404 && error.url.includes('matters')) {
                  this.router.navigateByUrl('/matters');
                }
                if (error.error.detail && !error.error.detail.includes('correspondence')) {
                  this.toastrService.error(error.error.detail);
                }
              }
              return throwError(error);
            }
          }),
        );
      }),
    );

  }

  private addAuthenticationToken(request: HttpRequest<any>) {
    if (!this.authenticationService.getJwtToken()) {
      return request;
    }
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, `Bearer ${this.authenticationService.getJwtToken()}`),
    });
  }

  private refreshAccessToken(): Observable<ResponseBodyAuth> {
    return this.authenticationService.refreshToken();
  }

  changeToHydra(request) {

    request.body = JSON.parse(JSON.stringify(request.body));
    if (request.body.companyWorkGroup) {
      if (request.body.companyWorkGroup?.id) {
        request.body.companyWorkGroup = '/company_work_groups/' + request.body.companyWorkGroup.id;
      } else {
        request.body.companyWorkGroup = '/company_work_groups/' + request.body.companyWorkGroup;
      }
    }

    if (request.body.client) {
      request.body.client = '/contacts/' + request.body.client;
    }
    if (request.body.executor) {
      request.body.executor = '/contacts/' + request.body.executor;
    }
    if (request.body.clientPayer) {
      request.body.clientPayer = '/contacts/' + request.body.clientPayer;
    }

    if (request.body.tariff) {
      if (request.url.includes('subscription_subs')) {
        request.body.tariff = '/tariff_subs/' + request.body.tariff;
      } else {
        request.body.tariff = '/tariffs/' + request.body.tariff;
      }
    }

    if (request.body.mandatory) {
      if (request.body.mandatory.responsibilities?.length) {
        request.body.mandatory.responsibilities = request.body.mandatory.responsibilities.map((user: string) => ('/users/' + user));

      }
      if (request.body.mandatory.contract) {
        request.body.mandatory.contract = '/contracts/' + request.body.mandatory.contract;
      }
      if (request.body.mandatory.contact) {
        request.body.mandatory.contact = '/contacts/' + request.body.mandatory.contact;
      }
      if (request.body.mandatory.responsibleContact) {
        request.body.mandatory.responsibleContact = '/contacts/' + request.body.mandatory.responsibleContact;
      }
    }

    if (request.body.orderItem) {
      if (request.body.orderItem.responsible) {
        request.body.orderItem.responsible = '/users/' + request.body.orderItem.responsible;
      }
      if (request.body.orderItem.contract) {
        request.body.orderItem.contract = '/contracts/' + request.body.orderItem.contract;
      }
      if (request.body.orderItem.contact) {
        request.body.orderItem.contact = '/contacts/' + request.body.orderItem.contact;
      }
      if (request.body.orderItem.responsibleContact) {
        request.body.orderItem.responsibleContact = '/contacts/' + request.body.orderItem.responsibleContact;
      }
    }

    if (request.body.tariffPrice) {
      request.body.tariffPrice = '/tariff_prices/' + request.body.tariffPrice;
    }
    if (request.body.tariffSubPrice) {
      request.body.tariffPrice = '/tariff_sub_prices/' + request.body.tariffSubPrice;
      delete request.body.tariffSubPrice;
    }

    if (request.body.payer) {
      request.body.payer = '/contacts/' + (request.body.payer?.id || request.body.payer);

    }
    if (request.body.account) {
      request.body.account = '/accounts/' + request.body.account;
    }

    if (request.body.contactCompany) {
      if (request.body.contactCompany.director) {
        request.body.contactCompany.director = '/persons/' + request.body.contactCompany.director;
      }
    }

    if (request.body.pinMatters?.length) {
      request.body.pinMatters = request.body.pinMatters.map((matter: string) => ('/matters/' + matter));
    }
    if (request.body.unpinMatters?.length) {
      request.body.unpinMatters = request.body.unpinMatters.map((matter: string) => ('/matters/' + matter));
    }

    // if (request.body.groups?.length) {
    //   request.body.groups = request.body.groups.map((group: string) => ('/company_work_groups/' + group));

    // }
    if (request.body.pinComments?.length) {
      request.body.pinComments = request.body.pinComments.map((comm: string) => ('/comments/' + comm));
    }
    if (request.body.unpinComments?.length) {
      request.body.unpinComments = request.body.unpinComments.map((comm: string) => ('/comments/' + comm));
    }

    if (request.body.contact && !request.url.includes('send-email')) {
      request.body.contact = '/contacts/' + request.body.contact;
    }
    if (request.body.contract) {
      request.body.contract = '/contracts/' + request.body.contract;
    }
    if (request.body.correspondence) {
      request.body.correspondence = '/correspondences/' + request.body.correspondence;
    }
    if (request.body.timeEntry && !request.body.timeEntry?.currency) {
      request.body.timeEntry = '/time_entries/' + request.body.timeEntry;
    }


    if (request.body.responsibleSolicitor) {
      request.body.responsibleSolicitor = '/users/' + request.body.responsibleSolicitor;
    }
    if (request.body.executorSignatoryInvoice) {
      request.body.executorSignatoryInvoice = '/users/' + request.body.executorSignatoryInvoice;
    }

    if (request.body.originatingSolicitor) {
      request.body.originatingSolicitor = '/users/' + request.body.originatingSolicitor;
    }
    if (request.body.parent?.id) {
      request.body.parent = '/expense_types/' + request.body.parent?.id;
    }
    if (request.body.parent && request.url.includes('correspondence')) {
      request.body.parent = '/correspondences/' + request.body.parent;
    }

    if (request.body.users) {
      request.body.users = request.body.users.map(id => '/users/' + id);
    }
    if (request.body.notifyUsers) {
      request.body.notifyUsers = request.body.notifyUsers.map(id => '/users/' + id);
    }
    if (request.body.taskUsers) {
      request.body.taskUsers = request.body.taskUsers.map(tU => ({user: '/users/' + tU.user}));
    }
    if (request.body.bills) {
      request.body.bills = request.body.bills.map(id => ('/bills/' + id));
    }
    if (request.body.additionalMatters) {
      request.body.additionalMatters = request.body.additionalMatters.map(id => ('/matters/' + id));
    }

    if (request.body.documents) {
      request.body.documents = request.body.documents.map(docId => ('/documents/' + docId));
    }
    if (request.body.customRates) {
      request.body.customRates = request.body.customRates.map(rate => ({
        ...rate,
        companyWorkGroup: '/company_work_groups/' + rate.companyWorkGroup,
        currency: '/reg_currencies/' + rate.currency,
      }));
    }

    if (request.body.rates) {
      request.body.rates = request.body.rates.map(rate => {
        if (!rate.id) {
          delete rate.id;
        }
        return {
          ...rate,
          currency: '/reg_currencies/' + rate.currency,
        };
      });
    }

    if (request.body.matterEstimateExpenses) {
      request.body.matterEstimateExpenses = request.body.matterEstimateExpenses.map(expense => {
        if (!expense.id) {
          delete expense.id;
        }
        return {
          ...expense,
          expenseType: '/expense_types/' + (expense.expenseType?.id || expense.expenseType),
        };
      });
    }

    if (request.body.courtSession) {
      request.body.courtSession = '/court_sessions/' + request.body.courtSession;
    }
    if (request.body.currencyBill) {
      request.body.currencyBill = '/reg_currencies/' + request.body.currencyBill;
    }
    if (request.body.currencyInvoice) {
      request.body.currencyInvoice = '/reg_currencies/' + request.body.currencyInvoice;
    }
    if (request.body.currencyMatterAmount) {
      request.body.currencyMatterAmount = '/reg_currencies/' + request.body.currencyMatterAmount;
    }
    if (request.body.timeEntry?.currency) {
      request.body.timeEntry.currency = '/reg_currencies/' + request.body.timeEntry.currency;
    }
    if (request.body.timeEntry?.responsiblePerson) {
      request.body.timeEntry.responsiblePerson = '/users/' + request.body.timeEntry.responsiblePerson;
    }

    if (request.body.currency) {
      request.body.currency = '/reg_currencies/' + request.body.currency;
    }
    if (request.body.position) {
      request.body.position = '/reg_position/' + request.body.position;
    }
    if (request.body.employees) {
      request.body.employees = '/reg_staff_qty/' + request.body.employees;
    }
    if (request.body.bill) {
      request.body.bill = '/bills/' + request.body.bill;
    }
    if (request.url.includes('contact_relationships')) {
      if (request.body.type) {
        request.body.type = '/relationship_types/' + request.body.type;
      }
      if (request.body.source) {
        request.body.source = '/contacts/' + request.body.source;
      }
      if (request.body.target) {
        request.body.target = '/contacts/' + request.body.target;
      }
    }
    if (request.url.includes('document_relationships')) {

      if (request.body.source) {
        request.body.source = '/documents/' + request.body.source;
      }
      if (request.body.target) {
        request.body.target = '/documents/' + request.body.target;
      }
    }


    if (request.body.userPortal) {
      request.body.userPortal = '/users/' + request.body.userPortal;
    }

    if (request.body.user?.person && !request.url.includes('company_user_accesses')) {
      request.body.user.person = '/persons/' + request.body.user.person.id;
    }

    if (request.body.practiceBranch) {
      request.body.practiceBranch = '/practice_branches/' + request.body.practiceBranch;
    }
    if (request.body.matter) {
      request.body.matter = '/matters/' + (request.body.matter?.id || request.body.matter);
    }
    if (request.body.responsiblePerson) {
      request.body.responsiblePerson = '/users/' + request.body.responsiblePerson;
    }

    if (request.body.companyBankAccount && !(request.body.companyBankAccount + '').includes('company_bank_accounts')) {
      request.body.companyBankAccount = '/company_bank_accounts/' + request.body.companyBankAccount;
    }
    if (request.body.companyBankAccountFrom) {
      request.body.companyBankAccountFrom = '/company_bank_accounts/' + request.body.companyBankAccountFrom;
    }
    if (request.body.companyBankAccountTo) {
      request.body.companyBankAccountTo = '/company_bank_accounts/' + request.body.companyBankAccountTo;
    }
    if (request.body.expenseType) {
      request.body.expenseType = '/expense_types/' + request.body.expenseType;
    }
    if (request.body.vendor) {
      request.body.vendor = '/contacts/' + request.body.vendor;
    }
    if (request.body.timeEntryType) {
      request.body.timeEntryType = '/time_entry_types/' + request.body.timeEntryType;
    }
    if (request.body.task) {
      request.body.task = '/tasks/' + request.body.task;
    }
    if (request.body.personType) {
      request.body.personType = '/person_types/' + request.body.personType;
    }
    if (request.body.media) {
      request.body.media = '/media/' + (request.body.media?.id || request.body.media);
    }

    if (request.body.accountDeleteType) {
      request.body.accountDeleteType = '/reg_account_delete_type/' + request.body.accountDeleteType;
    }
    if (request.body.category) {
      request.body.category = '/document_categories/' + request.body.category;
    }
    if (request.body.subscription) {
      request.body.subscription = '/subscriptions/' + request.body.subscription;
    }
    if (request.body.subscriptionSubs) {
      request.body.subscription = '/subscription_subs/' + request.body.subscriptionSubs;
      delete request.body.subscriptionSubs;
    }



    if (request.body.companySettings) {
      request.body.companySettings = '/company_settings/' + request.body.companySettings;
    }
    if (request.body.document) {
      request.body.document = '/documents/' + request.body.document;
    }
    if (request.body.template) {
      request.body.template = '/templates/' + request.body.template;
    }

    if (request.body.addresses?.length) {
      request.body.addresses = request.body.addresses.map(add => {
        if (!add.id) {
          delete add.id;
        }
        return add;
      });
    }
    if (request.body.bankDetails?.length) {
      request.body.bankDetails = request.body.bankDetails.map(bank => {
        if (!bank.id) {
          delete bank.id;
        }
        return bank;
      });
    }
    if (request.body.registrationActions?.length) {
      request.body.registrationActions = request.body.registrationActions.map(reg => {
        if (!reg.id) {
          delete reg.id;
        }
        return reg;
      });
    }
    if (request.body.phones?.length) {
      request.body.phones = request.body.phones.map(phone => {
        if (!phone.id) {
          delete phone.id;
        }
        return phone;
      });
    }
    if (request.body.emails?.length) {
      request.body.emails = request.body.emails.map(email => {
        if (!email.id) {
          delete email.id;
        }
        return email;
      });
    }
    if (request.body.websites?.length) {
      request.body.websites = request.body.websites.map(site => {
        if (!site.id) {
          delete site.id;
        }
        return site;
      });
    }
    if (request.body.messengers?.length) {
      request.body.messengers = request.body.messengers.map(msg => {
        if (!msg.id) {
          delete msg.id;
        }
        return msg;
      });
    }


    if (request.body.itemExpenses?.length) {
      request.body.itemExpenses = request.body.itemExpenses.map(iE => {
        if (!iE.id) {
          delete iE.id;
        }
        iE.expense = 'expenses/' + iE.expense;
        iE.currency = '/reg_currencies/' + iE.currency;
        iE.responsiblePerson = '/users/' + iE.responsiblePerson;
        if (iE.user) {
          iE.user = '/users/' + iE.user;
        }
        if (iE.expenseType) {
          iE.expenseType = '/expense_types/' + iE.expenseType;
        }
        return iE;
      });
    }

    if (request.body.itemTimeEntries?.length) {
      request.body.itemTimeEntries = request.body.itemTimeEntries.map(iE => {
        if (!iE.id) {
          delete iE.id;
        }
        iE.timeEntry = 'time_entries/' + iE.timeEntry;
        iE.responsiblePerson = '/users/' + iE.responsiblePerson;
        iE.currency = '/reg_currencies/' + iE.currency;
        if (iE.timeEntryType) {
          iE.timeEntryType = '/time_entry_types/' + iE.timeEntryType;
        }

        return iE;
      });
    }

    return request;
  }
}
