import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SafePipe } from '@app/common/pipes/safe.pipe';
import { ChangeColumnComponent } from '@app/commonComponents/change-column/change-column.component';
import { RowMenuComponent } from '@app/commonComponents/change-column/row-menu/row-menu.component';
import { UsersPopupListComponent } from '@app/commonComponents/change-column/users-popup-list/users-popup-list.component';
import { KanbanItemComponent } from '@app/commonComponents/kanban/kanban-item/kanban-item.component';
import { KanbanComponent } from '@app/commonComponents/kanban/kanban.component';
import { PaginationModule } from '@app/commonComponents/pagination/pagination.module';
import { TabsNavComponent } from '@app/commonComponents/tabs-nav/tabs-nav.component';
import { TasksFilterComponent } from '@app/modules/reusable/tasks/components/tasks-filter/tasks-filter.component';
import { TasksComponent } from '@app/modules/reusable/tasks/components/tasks/tasks.component';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ComboBoxModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { CommonsModule } from '../common/commons.module';
import { SharedModule } from '../shared.modules';
import { PerfectScrollbarModule } from '@app/perfect-scrollbar/perfect-scrollbar.module';
import { LoaderModule } from '@app/commonComponents/loader/loader.module';

@NgModule({
  declarations: [
    ChangeColumnComponent,
    SafePipe,
    TasksComponent,
    TasksFilterComponent,
    TabsNavComponent,
    KanbanComponent,
    RowMenuComponent,
    KanbanItemComponent,
    UsersPopupListComponent
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    TranslateModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    ComboBoxModule,
    MultiSelectModule,
    NumericTextBoxModule,
    PerfectScrollbarModule,
    RouterModule,
    LoaderModule,
    PaginationModule,
    DatePickerModule,
    DragDropModule,
    CommonsModule,
    SharedModule
  ],
  exports: [
    ChangeColumnComponent,
    SafePipe,
    TasksComponent,
    TasksFilterComponent,
    TabsNavComponent,
    KanbanComponent,
    RowMenuComponent,
    KanbanItemComponent
  ]
})
export class ColumnsModule {}
