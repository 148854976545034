import { Component, Output, EventEmitter } from '@angular/core';
import { TaskExtended } from '@app/models';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { Router } from '@angular/router';
import { ContactService } from '@app/modules/contacts/services/contact.service';
import { TasksService } from '@app/modules/main-tasks/services/tasks.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDrag } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TimeModalComponent } from '../wide-modals/time-modal/time-modal.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.scss']
})
export class KanbanComponent {
  constructor(
    private taskService: TasksService,
    private router: Router,
    private contactService: ContactService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  dragDiretion = null;

  @Output() viewItem = new EventEmitter();
  @Output() editItem = new EventEmitter();
  @Output() completeTask = new EventEmitter();
  @Output() deleteItem = new EventEmitter();
  tasksList: TaskExtended[];
  spinnerName = 'kanbanList';
  showLoader = false;

  newKanban: TaskExtended[] = [];
  inWorkKanban: TaskExtended[] = [];
  doneKanban: TaskExtended[] = [];
  cancelKanban: TaskExtended[] = [];

  tooltipPosition = {
    visible: false,
    top: 0,
    left: 0,
    topPosition: false,
    text: ''
  };

  memberPosition = {
    visible: false,
    top: 0,
    left: 0,
    topPosition: false,
    members: []
  };

  menuPosition = {
    item: null,
    top: 0,
    left: 0,
    topPosition: false
  };

  groups = [
    {
      name: 'reports.countTaskNew',
      key: 'new',
      orderCanban: 0,
      items: [],
      predicate(item: CdkDrag<TaskExtended>) {
        return item.data.status === 'new';
      }
    },
    {
      name: 'reports.countTaskInWork',
      key: 'in_work',
      orderCanban: 1,
      items: [],
      predicate(item: CdkDrag<TaskExtended>) {
        return item;
      }
    },
    {
      name: 'reports.countTaskDone',
      key: 'done',
      orderCanban: 2,
      items: [],
      predicate(item: CdkDrag<TaskExtended>) {
        return item;
      }
    },
    {
      name: 'reports.countTaskCanceled',
      key: 'cancel',
      orderCanban: 3,
      items: [],
      predicate(item: CdkDrag<TaskExtended>) {
        return item;
      }
    }
  ];

  newOrderCurrentItem = 0;

  cardMoveDirection = 0;

  tooltipDisplay(data) {
    const { event, text } = data;
    if (!event) {
      this.tooltipPosition.visible = false;
      return;
    }

    const { elementPosition, topPosition } = this.getOffsetPosition(event, 40);

    this.tooltipPosition = {
      visible: true,
      top: topPosition ? elementPosition.top - 10 : elementPosition.top + elementPosition.height + 10,
      left: elementPosition.left + elementPosition.width / 2,
      topPosition,
      text
    };
  }

  memberDisplay(data) {
    const { event, members } = data;
    if (!event) {
      this.memberPosition.visible = false;
      return;
    }

    const { elementPosition, topPosition } = this.getOffsetPosition(event, 200);

    this.memberPosition = {
      visible: true,
      top: topPosition ? elementPosition.top : elementPosition.top + elementPosition.height,
      left:
        window.innerWidth - elementPosition.right < 100
          ? elementPosition.left + elementPosition.width
          : elementPosition.left + elementPosition.width + 65,
      topPosition,
      members
    };
  }

  menuDisplay(data) {
    if (!data) {
      this.menuPosition = {
        item: null,
        top: 0,
        left: 0,
        topPosition: false
      };
      return;
    }

    const { event, item } = data;
    const { elementPosition, topPosition } = this.getOffsetPosition(event, 150);

    this.menuPosition = {
      item,
      top: topPosition ? elementPosition.top + 20 : elementPosition.top,
      left: elementPosition.left + elementPosition.width,
      topPosition
    };
  }

  getShortName(name) {
    const names = name?.split(' ');
    return names && names[0][0] + names[1][0];
  }

  getOffsetPosition(event, elementHeight) {
    const elementPosition = event.target.getBoundingClientRect();
    const topPosition = window.innerHeight - elementPosition.top - elementPosition.height < elementHeight;

    return { elementPosition, topPosition };
  }

  sortingData(tasksList) {
    this.tasksList = tasksList;
    this.newKanban = [];
    this.inWorkKanban = [];
    this.doneKanban = [];
    this.cancelKanban = [];

    tasksList.map((item: TaskExtended) => {
      // прострочені таски
      item.dateExpired = false;
      if (item.type === 'event' && new Date() > new Date(item.endDate)) {
        item.dateExpired = true;
      }
      const today = CustomDateAdapter.today().split('.');
      const endDate = CustomDateAdapter.convertToDate(new Date(item.endDate)).split('.');
      if (item.type === 'task' && new Date(`${today[2]}.${today[1]}.${today[0]}`) > new Date(`${endDate[2]}.${endDate[1]}.${endDate[0]}`)) {
        item.dateExpired = true;
      }

      if (item.status === 'new') {
        if (item.orderCanban === 0) {
          item.orderCanban = item.id * 100;
        }
        this.newKanban.push(item);
      }
      if (item.status === 'in_work') {
        if (item.orderCanban === 0) {
          item.orderCanban = item.id * 100;
        }
        this.inWorkKanban.push(item);
      }
      if (item.status === 'done') {
        if (item.orderCanban === 0) {
          item.orderCanban = item.id * 100;
        }
        this.doneKanban.push(item);
      }
      if (item.status === 'cancel') {
        if (item.orderCanban === 0) {
          item.orderCanban = item.id * 100;
        }
        this.cancelKanban.push(item);
      }
    });
    this.groups[0].items = this.newKanban.sort((a, b) => a.orderCanban - b.orderCanban);
    this.groups[1].items = this.inWorkKanban.sort((a, b) => a.orderCanban - b.orderCanban);
    this.groups[2].items = this.doneKanban.sort((a, b) => a.orderCanban - b.orderCanban);
    this.groups[3].items = this.cancelKanban.sort((a, b) => a.orderCanban - b.orderCanban);
  }

  deleteDuplicate(task) {
    for (const group of this.groups) {
      for (let j = 0; j < group.items.length; j++) {
        if (task.id === group.items[j].id) {
          group.items.splice(j, 1);
        }
      }
    }
  }

  updatedItem(dataTask: { type: string; task: TaskExtended }) {
    const idxColumn = this.groups.findIndex(group => group.key === dataTask.task.status);
    const idxTask = this.groups[idxColumn].items.findIndex(item => item.id === dataTask.task.id);
    if (dataTask.type === 'delete' && idxTask !== -1) {
      this.groups[idxColumn].items.splice(idxTask, 1);
    } else if (dataTask.type !== 'delete') {
      if (idxTask !== -1) {
        this.groups[idxColumn].items[idxTask] = dataTask.task;
      } else {
        this.deleteDuplicate(dataTask.task);
        this.groups[idxColumn].items.push(dataTask.task);
      }
    }
    this.groups[idxColumn].items = this.groups[idxColumn].items.sort((a, b) => a.orderCanban - b.orderCanban);
  }

  private openSpinner() {
    this.showLoader = true;
  }

  private hideSpinner() {
    this.showLoader = false;
  }

  goToWorker(user) {
    this.openSpinner();
    this.contactService.getUserById(user.id).subscribe(res => {
      this.hideSpinner();
      if (res.id) {
        this.router.navigateByUrl(`/worker/${user.id}`);
      }
    });
  }

  onViewItem(item) {
    this.viewItem.emit(item);
  }

  onEditItem(item) {
    this.menuPosition.item = null;
    this.editItem.emit(item);
  }

  completeItem(item) {
    this.menuPosition.item = null;
    this.completeTask.emit(item);
  }

  onDeleteItem(item) {
    this.menuPosition.item = null;
    this.deleteItem.emit(item);
  }

  changeOrderTasks(tasks) {
    this.taskService.changeOrderCanbanTasks(tasks).subscribe(res => {
      if (res.id && tasks.status === 'done') {
        this.openTimeModal(res);
      }
    });
  }

  drop(event: CdkDragDrop<TaskExtended[]>) {
    this.cardMoveDirection = 0;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    const previousItem = event.container.data[event.currentIndex - 1];
    const nextItem = event.container.data[event.currentIndex + 1];
    let newOrder = 1;
    if (previousItem && nextItem) {
      newOrder = (previousItem.orderCanban + nextItem.orderCanban) / 2;
    } else if (previousItem && !nextItem) {
      newOrder = previousItem.orderCanban * 2;
    } else if (!previousItem && nextItem) {
      newOrder = nextItem.orderCanban / 2;
    } else {
      newOrder = event.item.data.id * 100;
    }

    event.container.data[event.currentIndex].orderCanban = newOrder > 0 ? newOrder : 1;
    this.changeOrderTasks({
      orderCanban: Math.round(newOrder > 0 ? newOrder : 1),
      status: event.container.id,
      id: event.item.data.id
    });
  }

  movedItem(e) {
    this.cardMoveDirection = e.delta.x;
  }

  openTimeModal(task) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog', 'time-modal'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { task, matter: task.matter };

    const dialogRef = this.dialog.open(TimeModalComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }
  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }
}
