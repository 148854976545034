import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  transition('* => *', [
    query(':enter, :leave', style({ position: 'absolute', width: '100%' }), { optional: true }),

    query(':enter', [style({ opacity: 0 })], { optional: true }),

    query(':leave', [style({ opacity: 1 }), animate('0.2s', style({ opacity: 0 }))], { optional: true }),

    query(':enter', [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))], { optional: true })
  ])
]);

export const disappearanceAnimation = trigger('disappearanceAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('.3s', style({ opacity: 1 }))]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate(
      '.3s ease',
      style({
        opacity: 0
      })
    )
  ])
]);

export const translateAnimation = trigger('translateAnimation', [
  transition(':enter', [
    style(
      { opacity: 0, transform: 'translateX(-3%)' }
      ), animate('.3s cubic-bezier(.59,.02,.23,1)', style({ opacity: 1 , transform: 'translateX(0)' }))]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateX(0)' }),
    animate(
      '.3s cubic-bezier(.59,.02,.23,1)',
      style({
        opacity: 0,
        transform: 'translateX(-3%)'
      })
    )
  ])
]);

export const translateTopAnimation = trigger('translateTopAnimation', [
  transition(':enter', [
    style(
      { opacity: 0, transform: 'translateY(-3%)' }
      ), animate('.240s cubic-bezier(.59,.02,.23,1)', style({ opacity: 1 , transform: 'translateY(0)' }))]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0)' }),
    animate(
      '.240s cubic-bezier(.59,.02,.23,1)',
      style({
        opacity: 0,
        transform: 'translateY(-3%)'
      })
    )
  ])
]);

export const fadeInOutAnimation = trigger('fadeInOutAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('.3s', style({ opacity: 1 }))]),
  transition(':leave', [
    style({ opacity: 1 }),
    group([
      animate(
        '.3s ease',
        style({
          opacity: 0,
          marginTop: '-0%'
        })
      ),
      animate(
        '.3s ease',
        style({
          height: 0
        })
      )
    ])
  ])
]);

export const fadeLeftAnimation = trigger('fadeLeftAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('.3s', style({ opacity: 1 }))]),
  transition(':leave', [
    style({ opacity: 1 }),
    group([
      animate(
        '.3s ease',
        style({
          opacity: 0,
          marginLeft: '-100%'
        })
      ),
      animate(
        '.3s ease',
        style({
          height: 0
        })
      )
    ])
  ])
]);

export const fadeTopAnimation = trigger('fadeTopAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('.3s', style({ opacity: 1, right: 0 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('.3s ease', style({ opacity: 0, right: -350 }))])
]);

export const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    /** empty because of 0 sec animation */
  ])
]);
