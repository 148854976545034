import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, mergeMap, share, switchMap } from 'rxjs/operators';
import {
  ChangePassword,
  ChangePasswordSuccess,
  ETeamMemberActions,
  FetchById,
  Fetched,
  FetchedById,
  FetchedUserRoleById,
  GetTeamMembers,
  Invite,
  Invited,
  LoadAdministratorsAndAccountsForList,
  LoadAdministratorsAndAccountsForListFailure,
  LoadAdministratorsAndAccountsForListSuccess,
  LoadAllUsersForList,
  LoadAllUsersForListFailure,
  LoadAllUsersForListSuccess,
  LoadAllUsersForReportsListFailure,
  LoadAllUsersForReportsListSuccess,
  LoadCountries,
  LoadCountriesFailure,
  LoadCountriesSuccess,
  LoadUsersForEntityList,
  LoadUsersForEntityListFailure,
  LoadUsersForEntityListSuccess,
  LoadUsersForList,
  LoadUsersForListFailure,
  LoadUsersForListSuccess,
  UpdateAllUsersForList,
  UpdateAllUsersForListFailure,
  UpdateAllUsersForListSuccess,
  UpdateTeamMember,
  UpdateTeamMemberPreferences,
  UpdateTeamMemberSuccess,
  UpdateUser,
  UpdateUsersForList,
  UpdateUsersForListFailure,
  UpdateUsersForListSuccess,
  UpdateUserSuccess,
} from '@app/modules/settings/store/actions/team-member.actions';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';
import { GetAnyoneWithoutSystemWorkGroups, GetAnyoneWorkGroups, GetMyAndAnyoneWorkGroups, GetWorkGroups } from '../actions/team.actions';

@Injectable()
export class TeamMemberEffects {

  fetch$ = createEffect(() => this.actions$.pipe(
    ofType<GetTeamMembers>(ETeamMemberActions.GET_TEAM_MEMBERS),
    switchMap(action => {
      return this.teamMemberService.get(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(response => {
          return [new Fetched(response)];
        }),
      );
    }),
    share(),
  ));


  loadUsersForList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUsersForList>(ETeamMemberActions.LOAD_USERS_FOR_LIST),
    switchMap(action => {
      return this.teamMemberService
        .get(action.payload.page, action.payload.itemsPerPage, {
          status: 'active',
          'companyAccesses.enable': true,
          ...action.payload.filters
        })
        .pipe(
          mergeMap(resp => {
            return [new LoadUsersForListSuccess(resp.member)];
          }),
          catchError(error => of(new LoadUsersForListFailure(error))),
        );
    }),
    share(),
  ));


  updateUsersForList$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateUsersForList>(ETeamMemberActions.UPDATE_USERS_FOR_LIST),
    switchMap(action => {
      return this.teamMemberService.getById(action.payload).pipe(
        mergeMap(resp => {
          return [new UpdateUsersForListSuccess(resp)];
        }),
        catchError(error => of(new UpdateUsersForListFailure(error))),
      );
    }),
    share(),
  ));


  loadUsersForEntityList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUsersForEntityList>(ETeamMemberActions.LOAD_USERS_FOR_ENTITY_LIST),
    switchMap(action => {
      return this.teamMemberService.get(action.payload.page, action.payload.itemsPerPage, action.payload.filters).pipe(
        mergeMap(resp => {
          return [new LoadUsersForEntityListSuccess(resp.member)];
        }),
        catchError(error => of(new LoadUsersForEntityListFailure(error))),
      );
    }),
    share(),
  ));


  loadAllUsersForList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadAllUsersForList>(ETeamMemberActions.LOAD_ALL_USERS_FOR_LIST),
    switchMap(action => {
      return this.teamMemberService
        .get(action.payload.page, action.payload.itemsPerPage, {
          status: 'active',
        })
        .pipe(
          mergeMap(resp => {
            return [new LoadAllUsersForListSuccess(resp.member)];
          }),
          catchError(error => of(new LoadAllUsersForListFailure(error))),
        );
    }),
    share(),
  ));

  loadAllUsersForReprotsList$ = createEffect(() => this.actions$.pipe(
    ofType<LoadAllUsersForList>(ETeamMemberActions.LOAD_ALL_USERS_FOR_REPORTS_LIST),
    switchMap(action => {
      return this.teamMemberService
        .get(action.payload.page, action.payload.itemsPerPage, {
          status: 'active',
          type: 'user',
        })
        .pipe(
          mergeMap(resp => {
            return [new LoadAllUsersForReportsListSuccess(resp.member)];
          }),
          catchError(error => of(new LoadAllUsersForReportsListFailure(error))),
        );
    }),
    share(),
  ));

  updateAllUsersForList$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateAllUsersForList>(ETeamMemberActions.UPDATE_ALL_USERS_FOR_LIST),
    switchMap(action => {
      return this.teamMemberService.getById(action.payload).pipe(
        mergeMap(resp => {
          return [new UpdateAllUsersForListSuccess(resp)];
        }),
        catchError(error => of(new UpdateAllUsersForListFailure(error))),
      );
    }),
    share(),
  ));


  loadAllAdministratorsAndAccounts$ = createEffect(() => this.actions$.pipe(
    ofType<LoadAdministratorsAndAccountsForList>(ETeamMemberActions.LOAD_ADMINISTRATORS_AND_ACCOUNTS_FOR_LIST),
    switchMap(action => {
      return this.teamMemberService.getAdministratorsAndAccounts(action.payload.page, action.payload.itemsPerPage).pipe(
        mergeMap(resp => {
          return [new LoadAdministratorsAndAccountsForListSuccess(resp.member)];
        }),
        catchError(error => of(new LoadAdministratorsAndAccountsForListFailure(error))),
      );
    }),
    share(),
  ));


  loadCountries$ = createEffect(() => this.actions$.pipe(
    ofType<LoadCountries>(ETeamMemberActions.LOAD_COUNTRIES),
    switchMap(() => {
      return this.teamMemberService.getCountries().pipe(
        mergeMap(resp => {
          return [new LoadCountriesSuccess(resp.member)];
        }),
        catchError(error => of(new LoadCountriesFailure(error))),
      );
    }),
    share(),
  ));


  fetchById$ = createEffect(() => this.actions$.pipe(
    ofType<FetchById>(ETeamMemberActions.GET_TEAM_MEMBER_BY_ID),
    switchMap(action => {
      return this.teamMemberService.getById(action.payload).pipe(
        filter(x => !!x && !!x.id),
        mergeMap(response => {
          return [new FetchedById(response)];
        }),
      );
    }),
    share(),
  ));


  fetchUserRoleById$ = createEffect(() => this.actions$.pipe(
    ofType<FetchById>(ETeamMemberActions.FETCH_USER_ROLES),
    switchMap(action => {
      return this.teamMemberService.fetchUserRoleById(action.payload).pipe(
        filter(x => !!x && !!x.id),
        mergeMap(response => {
          return [new FetchedUserRoleById(response)];
        }),
      );
    }),
    share(),
  ));


  invite$ = createEffect(() => this.actions$.pipe(
    ofType<Invite>(ETeamMemberActions.INVITE),
    switchMap(action => {
      return this.teamMemberService.invite(action.payload).pipe(
        mergeMap(response => {
          if (response.id) {
            this.toastrService.success(this.translate.instant('inviteCreated'));
          }
          return [new Invited(response)];
        }),
      );
    }),
    share(),
  ));


  update$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTeamMember>(ETeamMemberActions.UPDATE_TEAM_MEMBER),
    switchMap(action => {
      return this.teamMemberService.update(action.id, action.payload).pipe(
        filter(x => !!x && !!x.id),
        mergeMap(response => {
          this.toastrService.success(this.translate.instant('itemAdded'));
          this.updateListGroup();
          return response && [new UpdateTeamMemberSuccess(response)];
        }),
      );
    }),
    share(),
  ));


  updatePreferences$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateTeamMemberPreferences>(ETeamMemberActions.UPDATE_TEAM_MEMBER_PREFERENCES),
    switchMap(action =>
      this.teamMemberService.updateBillingPreferences(action.payload, action.id).pipe(
        filter(x => !!x && !!x.id),
        mergeMap(response => {
          this.toastrService.success(this.translate.instant('itemEdited'));
          this.router.navigateByUrl(`/worker/${response.id}`);
          return [new UpdateTeamMemberSuccess(response)];
        }),
      ),
    ),
  ));


  changePassword$ = createEffect(() => this.actions$.pipe(
    ofType<ChangePassword>(ETeamMemberActions.CHANGE_PASSWORD),
    switchMap(action =>
      this.teamMemberService.changePassword(action.payload).pipe(
        filter(x => !!x && !!x.id),
        mergeMap(response => {
          this.toastrService.success(this.translate.instant('passwordChanged'));
          return [new ChangePasswordSuccess(response)];
        }),
      ),
    ),
    share(),
  ));


  updateUser$ = createEffect(() => this.actions$.pipe(
    ofType<UpdateUser>(ETeamMemberActions.UPDATE_USER),
    switchMap(action =>
      this.teamMemberService.changeOneUser(action.userId, action.payload).pipe(
        mergeMap(response => {
          return [new UpdateUserSuccess(response)];
        }),
        catchError(error => of(new UpdateUsersForListFailure(error))),
      ),
    ),
    share(),
  ));


  updateListGroup() {
    this.store.dispatch(new GetWorkGroups);
    this.store.dispatch(new GetMyAndAnyoneWorkGroups);
    this.store.dispatch(new GetAnyoneWorkGroups);
    this.store.dispatch(new GetAnyoneWithoutSystemWorkGroups);
  }

  constructor(
    private actions$: Actions,
    private teamMemberService: TeamMemberService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private store: Store<IAppState>,
    private router: Router,
  ) {
  }
}
