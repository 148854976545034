import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/_services/auth.service';
import { CustomDateAdapter } from '@app/helpers/custom-date-adapter';
import { Helpers } from '@app/helpers/helpers';
import { IUser, ResponseBodyFull } from '@app/models';
import { MatterExtended } from '@app/models/matter-extended';
import { MatterFilters } from '@app/models/matter-filters';
import { Paginator } from '@app/models/paginator';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MatterService {
  private BASE_URL = `${environment.apiUrl}/matters`;
  readonly BASE_URL_USERS = `${environment.apiUrl}/users`;

  constructor(private http: HttpClient, private authService: AuthService, private router: Router) {}

  get(currentPage = 1, itemsPerPage = environment.defaultItemsCount, filters: MatterFilters = null, exludeMatters = []): Observable<Paginator<MatterExtended>> {
    let params = new HttpParams().set('partial', 'false');
    params = params.set('order[pinned]', 'DESC');
    params = params.set('order[createdAt]', 'DESC');
    params = params.set('order[id]', 'DESC');

    if (currentPage && itemsPerPage) {
      params = params.set('page', currentPage.toString()).set('itemsPerPage', itemsPerPage.toString());
    }

    if (filters) {
      if (filters.status === 'all') {
        filters.status = null;
      }
      params = Helpers.populateFilters(params, filters, true);
    }
    if (this.authService.currentUserValue) {
      return this.http
        .get<ResponseBodyFull<MatterExtended>>(`${this.BASE_URL}?${this.getExludeMattersFilter(exludeMatters)}`, { params })
        .pipe(map(response => ({member: response['hydra:member'], ...response.pagination})));
    } else {
      return new Observable<Paginator<MatterExtended>>();
    }
  }

  getExludeMattersFilter(ids) {
    let strFilter = '';
    for (let i = 0; i < ids?.length; i++) {
      if (ids[i] && typeof ids[i] === 'number' && i !== ids.length - 2) {
        strFilter += ids[i] ? `exclude[]=${ids[i]}&` : '';
      } else {
        strFilter += ids[i] ? `exclude[]=${ids[i]}` : '';
      }
    }
    return strFilter;
  }

  getMatterById(id: number): Observable<MatterExtended> {
    if (id == null) {
      return null;
    }
    if (this.authService.currentUserValue) {
      return this.http.get<MatterExtended>(`${this.BASE_URL}/${id}`).pipe(
        map(response => {
          if (!response.id) {
            this.router.navigateByUrl(`/matters`);
          }
          return response;
        })
      );
    } else {
      return new Observable<MatterExtended>();
    }
  }

  deleteMatter(id) {
    return this.http.delete(`${this.BASE_URL}/${id}`);
  }

  setMatter(data: MatterExtended): Observable<MatterExtended> {
    return this.http.post<MatterExtended>(`${this.BASE_URL}`, data);
  }

  updateMatter(matter: MatterExtended): Observable<MatterExtended> {
    return this.http.patch<MatterExtended>(`${this.BASE_URL}/${matter.id}`, matter);
  }

  checkMatterTracker(key) {
    return this.http.get<any>(`${this.BASE_URL}/validate-court-case?court_case=${key}`);
  }

  // Convert matter for saving
  formDataToMatterSave(matter: MatterExtended): MatterExtended {
    if (matter?.amount) {
      matter.amount = matter?.amount.toString();
    }
    if (matter?.budgetAmount) {
      matter.budgetAmount = matter?.budgetAmount.toString();
    }
    matter.practiceBranch = matter?.practiceBranch;
    if (matter?.customRates) {
      matter.customRates = matter?.customRates;
    } else {
      matter.customRates = [];
    }

    if (matter?.pendingDate) {
      matter.pendingDate = CustomDateAdapter.convertToDate(matter?.pendingDate as Date);
    }
    if (matter.closedDate) {
      matter.closedDate = CustomDateAdapter.convertToDate(matter?.closedDate as Date);
    }
    matter.startDate = CustomDateAdapter.convertToDate(matter?.startDate as Date);

    return matter;
  }

  pinnedMatter(data): Observable<IUser> {
    return this.http.patch<IUser>(`${this.BASE_URL_USERS}/${this.authService.currentUserValue.id}`, data);
  }
}
